import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DASHBOARD_TAB } from '@core/enum/dashboard';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import {
  LeadDashboardChartData,
  GetDashboardParams,
  LeadDashboardStatisticData,
  UserDashboardStatisticData,
  UserDashboardChartData,
  PolicyDashboardStatisticData,
  ChartData,
  PolicyDashboardChartData,
  PerformanceDashboardStatisticData,
  LeadsByUsers
} from '@core/models/interfaces/dashboard';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private baseUrl = `${environment.API_URL}/crm/dashboards`;

  constructor(private httpClient: HttpClient) {}

  getDashboardParams(params: GetDashboardParams) {
    let queryParams = new HttpParams();

    if (params.userId) {
      queryParams = queryParams.append('userId', params.userId);
    }

    if (params.groupId) {
      queryParams = queryParams.append('groupId', params.groupId);
    }

    if (params.from) {
      queryParams = queryParams.append('from', params.from);
    }

    if (params.to) {
      queryParams = queryParams.append('to', params.to);
    }

    if (params.page) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params.size) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params.sorts) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return queryParams;
  }

  // LEAD
  getLeadStatisticalData(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('type', DASHBOARD_TAB.LEAD);

    return this.httpClient.get<ResponseApi<LeadDashboardStatisticData>>(`${this.baseUrl}/statistical`, {
      params: queryParams
    });
  }

  getLeadChartData(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('type', DASHBOARD_TAB.LEAD);

    return this.httpClient.get<ResponseApi<LeadDashboardChartData>>(`${this.baseUrl}/chart`, {
      params: queryParams
    });
  }

  // USERS
  getUserStatisticalData(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('type', DASHBOARD_TAB.USER);

    return this.httpClient.get<ResponseApi<UserDashboardStatisticData>>(`${this.baseUrl}/statistical`, {
      params: queryParams
    });
  }

  getUserChartData(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('type', DASHBOARD_TAB.USER);

    return this.httpClient.get<ResponseApi<UserDashboardChartData>>(`${this.baseUrl}/chart`, {
      params: queryParams
    });
  }

  // POLICY
  getPolicyStatisticalData(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('type', DASHBOARD_TAB.POLICY);

    return this.httpClient.get<ResponseApi<PolicyDashboardStatisticData>>(`${this.baseUrl}/statistical`, {
      params: queryParams
    });
  }

  getPolicyChartData(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);
    queryParams = queryParams.append('type', DASHBOARD_TAB.POLICY);

    return this.httpClient.get<ResponseApi<PolicyDashboardChartData>>(`${this.baseUrl}/chart`, {
      params: queryParams
    });
  }

  getPolicyProductionChartData(MONTH: string, userId?: string, groupId?: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('MONTH', MONTH);

    if (userId) {
      queryParams = queryParams.append('userId', userId);
    }

    if (groupId) {
      queryParams = queryParams.append('groupId', groupId);
    }

    return this.httpClient.get<ResponseApi<ChartData[]>>(`${this.baseUrl}/chart/policy/productions`, {
      params: queryParams
    });
  }

  //PERFORMANCE
  getPerformanceStatisticalData(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('type', DASHBOARD_TAB.PERFORMANCE);

    return this.httpClient.get<ResponseApi<PerformanceDashboardStatisticData>>(`${this.baseUrl}/statistical`, {
      params: queryParams
    });
  }

  getPerformanceLeadsChartData(params: GetDashboardParams, WEEK: string) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('WEEK', WEEK);

    return this.httpClient.get<ResponseApi<ChartData[]>>(`${this.baseUrl}/chart/performance/leads`, {
      params: queryParams
    });
  }

  getPerformanceNotesChartData(params: GetDashboardParams, WEEK: string) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('WEEK', WEEK);

    return this.httpClient.get<ResponseApi<ChartData[]>>(`${this.baseUrl}/chart/performance/notes`, {
      params: queryParams
    });
  }

  getPerformanceTasksChartData(params: GetDashboardParams, WEEK: string) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('WEEK', WEEK);

    return this.httpClient.get<ResponseApi<ChartData[]>>(`${this.baseUrl}/chart/performance/tasks`, {
      params: queryParams
    });
  }

  getPerformanceAppointmentChartData(params: GetDashboardParams, WEEK: string) {
    let queryParams = this.getDashboardParams(params);

    queryParams = queryParams.append('WEEK', WEEK);

    return this.httpClient.get<ResponseApi<ChartData[]>>(`${this.baseUrl}/chart/performance/appointments`, {
      params: queryParams
    });
  }

  getLeadsByUsers(params: GetDashboardParams) {
    let queryParams = this.getDashboardParams(params);

    return this.httpClient.get<ResponseListApi<LeadsByUsers>>(`${this.baseUrl}/chart/user/lead`, {
      params: queryParams
    });
  }
}
