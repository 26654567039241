import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LeadNote } from '@core/models/interfaces/lead';
import { PolicyDetails } from '@core/models/interfaces/lead/lead-policys';
import { NoteStatus } from '@core/models/interfaces/note';
import { PolicyService } from '@core/services/policies/policy.service';
import { Subject, takeUntil } from 'rxjs';
import { LeadNoteItemComponent } from '../lead-note-item/lead-note-item.component';

@Component({
  selector: 'app-note-management-policy',
  standalone: true,
  imports: [LeadNoteItemComponent],
  templateUrl: './note-management-policy.component.html',
  styleUrl: './note-management-policy.component.scss'
})
export class NoteManagementPolicyComponent {
  @Input() leadId: string;
  @Input() noteStatuses: NoteStatus[] = [];
  @Input() note: LeadNote;

  @Output() onRefresh = new EventEmitter();

  readonly unsubscribe$ = new Subject();

  policies: PolicyDetails[] = [];

  constructor(private policyService: PolicyService) {}

  ngOnInit(): void {
    this.fetchPolicies();
  }

  fetchPolicies() {
    this.policyService
      .getPolicies({
        leadIds: [this.leadId],
        size: 100
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.policies = res?.data?.content;
        }
      });
  }

  fetchLeadNotes() {
    this.onRefresh.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
