<div class="flex flex-col gap-4 px-8">
  <!-- Table -->
  <p-table [value]="bannerState.banners" [loading]="bannerState.loading">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ translatePrefix + 'title' | translate }}</th>
        <th>{{ translatePrefix + 'attachment' | translate }}</th>
        <th>{{ translatePrefix + 'location' | translate }}</th>
        <th>{{ translatePrefix + 'updated-by' | translate }}</th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr class="h-[128px]">
        <td class="!px-3" [colSpan]="6"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-banner>
      <tr>
        <!-- Title -->
        <td>
          <div class="flex items-center gap-2 max-w-[480px] truncate">
            <span class="flex-1">{{ banner?.title ?? '--' }}</span>
          </div>
        </td>

        <!-- attachment -->
        <td>
          <div class="px-3.5 py-1.5">
            <app-custom-image
              [imgId]="banner?.attachment"
              alt=""
              imageClass="!max-h-10 !h-10 !w-auto object-cover rounded"></app-custom-image>
          </div>
        </td>

        <!-- location -->
        <td>
          {{ banner?.location ?? '--' }}
        </td>

        <!-- Updated by -->
        <td>
          <div class="flex gap-2 items-center">
            <app-custom-avatar
              [imgId]="banner?.createdBy?.avatar"
              [name]="banner?.createdBy?.fullName ?? ''"></app-custom-avatar>

            <span>{{ banner?.createdBy?.fullName ?? '--' }}</span>
          </div>
        </td>

        <!-- Action -->
        <td class="w-13">
          <div class="flex items-center" *ngxPermissionsOnly="Permissions.BANNER_EDIT">
            <div
              class="flex justify-center items-center w-9 h-9 hover:bg-neutral-200 hover:border cursor-pointer hover:border-neutral-200 rounded"
              (click)="onEditBanner(banner)">
              <i class="icon-wl-edit text-6"></i>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="8">
          <app-empty-table />
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Paginator -->
<div class="py-3 px-8">
  <app-custom-paginator [(pagination)]="pagination" [totalRecords]="bannerState.totalBanner"></app-custom-paginator>
</div>
<app-admin-content-banner-edit
  [(visible)]="isVisibleEditBanner"
  [bannerDetail]="bannerState.bannerCurrent"
  (onSuccess)="onUpdateSuccess()"></app-admin-content-banner-edit>
