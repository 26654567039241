import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LANGUAGES } from '@core/constants/coutries';
import { COLOR } from '@core/enum/color';
import { LocalStorageEnum } from '@core/enum/local-storage';
import {
  ChangePasswordBody,
  FileState,
  LanguageState,
  ThemeState,
  TwoFactorState
} from '@core/models/interfaces/account-setting';
import { UserInfo } from '@core/models/interfaces/auth';
import { ResponseApi } from '@core/models/interfaces/common';
import { UserDetail } from '@core/models/interfaces/user';
import { AccountSettingService } from '@core/services/account-setting.service';
import { StorageService } from '@core/services/storage.service';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { CheckPasswordValidator } from '@shared/validators/check-password.validator';
import { PasswordMatchValidator } from '@shared/validators/password-match.validator';
import { AppState } from '@state/app.state';
import { updateUserInfoField } from '@state/auth/auth.actions';
import { selectUserInfo } from '@state/auth/auth.selectors';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { forkJoin, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-account-setting',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    DividerModule,
    DropdownModule,
    ReactiveFormsModule,
    DialogModule,
    PasswordModule,
    CustomAvatarComponent,
    ButtonComponent,
    FormControlComponent,
    TranslateModule,
    InputSwitchModule,
    CustomLoadingComponent
  ],

  templateUrl: './account-setting.component.html',
  styleUrl: './account-setting.component.scss'
})
export class AccountSettingComponent {
  readonly translatePrefix = 'account-setting.';
  readonly messagePrefix = 'account-setting.message.';
  readonly LANGUAGES = LANGUAGES;
  readonly unsubscribe$ = new Subject();
  readonly COLORS_THEME = [
    COLOR.BLUE,
    COLOR.PURPLE,
    COLOR.CYAN,
    COLOR.GREEN,
    COLOR.YELLOW,
    COLOR.ORANGE,
    COLOR.RED,
    COLOR.PINK
  ];

  changePasswordForm = this.fb.group(
    {
      currentPassword: this.fb.control(
        '',
        [Validators.required],
        [CheckPasswordValidator.createValidator(this.accountSettingService)]
      ),
      password: this.fb.control('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]*$/)
      ]),
      confirmPassword: this.fb.control('', [Validators.required])
    },
    { validators: PasswordMatchValidator }
  );

  isChangePassword: boolean = false;
  isChangeAvatar: boolean = false;
  isDirty: boolean = false;
  isLoading: boolean = false;
  userInfo: UserDetail;

  languageState: LanguageState = {
    lang: 'en',
    isUpdate: false,
    loading: false
  };

  themeState: ThemeState = {
    theme: '',
    loading: false,
    isUpdate: false
  };

  twoFactorState: TwoFactorState = {
    loading: false,
    isTwoFactor: false,
    isUpdate: false
  };

  fileSelectState: FileState = {
    file: undefined,
    isUpdate: false,
    loading: false,
    avatarPreview: '',
    isRemoveAvatar: false
  };

  constructor(
    private fb: FormBuilder,
    private accountSettingService: AccountSettingService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectUserInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
          this.languageState.lang = this.userInfo.language ?? '';
          this.themeState.theme = this.userInfo.themeColor ?? '';
          this.twoFactorState.isTwoFactor = this.userInfo['2FA'];
        }
      });
  }

  get passwordControl() {
    return this.changePasswordForm.controls.password;
  }

  get isInvalidForm() {
    return this.changePasswordForm.invalid;
  }

  onCancelChangePassword() {
    this.isChangePassword = false;
    this.changePasswordForm.reset();
  }

  onChangePassword() {
    if (this.isInvalidForm) return;
    const valueForm = this.changePasswordForm.value;

    const body: ChangePasswordBody = {
      oldPassword: valueForm.currentPassword!,
      newPassword: valueForm.password!
    };
    this.accountSettingService
      .changePassword(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(this.messagePrefix + 'change-password-success')
          });
          this.isChangePassword = false;
          this.changePasswordForm.reset();
        },
        error: err => {
          if (err.status === 400) {
            this.messageService.add({
              severity: 'warn',
              detail: this.translateService.instant(this.messagePrefix + 'error-password')
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              detail: this.translateService.instant(this.messagePrefix + 'change-password-fail')
            });
          }
        }
      });
  }

  onRemoveAvatar() {
    this.isChangeAvatar = false;
    this.fileSelectState = {
      file: undefined,
      isUpdate: true,
      avatarPreview: null,
      isRemoveAvatar: true
    };
    this.userInfo = { ...this.userInfo, avatar: '' };
  }

  onFileSelect(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];

    if (file) {
      // Check if the selected file type is valid
      if (!allowedFormats.includes(file.type)) {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant('common.invalid-file-format')
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        this.fileSelectState = {
          file: file,
          isUpdate: true,
          avatarPreview: reader.result,
          isRemoveAvatar: false
        };

        this.isDirty = true;
        this.isChangeAvatar = true;
      };
      reader.readAsDataURL(file);
    }
  }

  onChangeTheme(color: COLOR) {
    this.themeState.theme = color;
    this.themeState.isUpdate = true;
    this.isDirty = true;
  }

  onChangeLanguage() {
    this.languageState.isUpdate = true;
    this.isDirty = true;
  }

  onCancel() {
    this.isDirty = false;
    this.fileSelectState.avatarPreview = '';
    this.isChangeAvatar = false;
    this.themeState.theme = this.userInfo.themeColor ?? '';
    this.languageState.lang = this.userInfo.language ?? 'en';
    this.twoFactorState.isTwoFactor = this.userInfo['2FA'];
  }

  onChangeTwoFactor(event: InputSwitchChangeEvent) {
    this.confirmationService.confirm({
      message: this.translateService.instant(
        this.messagePrefix + (event.checked ? 'confirm-enable-2fa' : 'confirm-disable-2fa')
      ),
      header: this.translateService.instant(this.translatePrefix + (event.checked ? 'enable-2fa' : 'disable-2fa')),
      acceptButtonStyleClass: 'btn-xl btn-primary',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.confirm'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => {
        this.twoFactorState = {
          isTwoFactor: event.checked,
          isUpdate: true,
          loading: false
        };
        this.isDirty = true;
      },
      reject: () => {
        this.twoFactorState.isTwoFactor = !event.checked;
      }
    });
  }

  updateLocalStorage(fieldName: string, value: string | boolean) {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      user[fieldName] = value;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  handleAvatarUpdate(sources: Observable<ResponseApi<UserDetail> | null>[]) {
    if (this.fileSelectState.isUpdate) {
      if (this.fileSelectState.isRemoveAvatar) {
        sources.push(this.accountSettingService.removeAvatarUser());
      } else {
        const uploadFile$ = this.storageService.uploadFile(this.fileSelectState.file!).pipe(
          switchMap(uploadResponse => {
            if (uploadResponse && uploadResponse.data) {
              return this.accountSettingService.updateAvatarUser(uploadResponse.data.id);
            }
            return of(null);
          })
        );
        sources.push(uploadFile$);
      }
    }
  }

  handleApiResponses(responses: (ResponseApi<UserDetail> | null)[]) {
    const responseMapping = {
      avatar: this.fileSelectState.isUpdate,
      twoFactor: this.twoFactorState.isUpdate,
      theme: this.themeState.isUpdate,
      language: this.languageState.isUpdate
    };

    const keysToUpdate = Object.keys(responseMapping).filter(
      key => responseMapping[key as keyof typeof responseMapping]
    );

    keysToUpdate.forEach((key, index) => {
      const response = responses[index];

      switch (key) {
        case 'avatar':
          this.updateUserInfo('avatar', response?.data?.avatar ?? '');
          break;
        case 'twoFactor':
          this.updateUserInfo('2FA', response?.data?.['2FA'] ?? false);
          break;
        case 'theme':
          this.updateUserInfo('themeColor', response?.data?.themeColor ?? '');
          break;
        case 'language':
          this.updateUserInfo('language', response?.data?.language ?? 'en');
          localStorage.setItem(LocalStorageEnum.LANGUAGE, response?.data?.language ?? 'en');
          break;
      }
    });
  }

  updateUserInfo(field: keyof UserInfo, value: any) {
    this.store.dispatch(updateUserInfoField({ field, value }));
    this.updateLocalStorage(field, value);
  }

  resetStates(): void {
    this.isLoading = false;
    this.fileSelectState.isUpdate = false;
    this.themeState.isUpdate = false;
    this.languageState.isUpdate = false;
    this.twoFactorState.isUpdate = false;
  }

  onSave() {
    let sources: Observable<ResponseApi<UserDetail> | null>[] = [];

    this.handleAvatarUpdate(sources);

    if (this.twoFactorState.isUpdate) {
      sources.push(this.accountSettingService.config2FA(this.twoFactorState.isTwoFactor));
    }

    if (this.themeState.isUpdate) {
      sources.push(this.accountSettingService.updateThemeColorUser(this.themeState.theme));
    }

    if (this.languageState.isUpdate) {
      sources.push(this.accountSettingService.updateLanguageUser(this.languageState.lang));
    }

    if (sources.length > 0) {
      this.isDirty = false;
      this.isLoading = true;

      forkJoin(sources)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: responses => {
            this.handleApiResponses(responses);

            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(this.messagePrefix + 'update-account-setting-success')
            });
            this.resetStates();
          },
          error: err => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              detail: this.translateService.instant(this.messagePrefix + 'update-account-setting-fail')
            });
            this.isDirty = true;
          }
        });
    } else {
      this.isDirty = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
