<!-- Data -->
<div class="cursor-pointer" (click)="visible = !visible">
  <div
    [style.backgroundColor]="color?.primaryColor"
    class="w-5 h-5 rounded-full"
    [ngClass]="color?.primaryColor ? '' : 'border'"></div>
</div>
<!-- Overlay -->
<p-overlay [(visible)]="visible">
  <div class="bg-white p-2 rounded-sm mt-1 border shadow-sm flex items-center gap-2">
    @for (color of Colors; track color.value) {
      <div
        class="transition group h-6 w-6 flex items-center justify-center rounded-full cursor-pointer hover:ring-2 hover:ring-offset-1 hover:ring-neutral-alpha-200A"
        [style.backgroundColor]="color.primaryColor"
        (click)="onSelectColor(color.primaryColor)">
        @if (color.value === value) {
          <img src="assets/images/icons/check-icon.svg" />
        }
      </div>
    }
  </div>
</p-overlay>
