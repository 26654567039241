import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { TreeNode } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeSelectModule } from 'primeng/treeselect';
import { Subject } from 'rxjs';

import { PerformanceParams } from '@core/models/interfaces/report';
import { UserSelect } from '@core/models/interfaces/user';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';

@Component({
  selector: 'app-performance-selection',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CalendarModule,
    TranslateModule,
    DropdownModule,
    TreeSelectModule,
    FormControlComponent,
    ButtonComponent,
    CustomAvatarComponent,
    CustomCalendarComponent
  ],
  templateUrl: './performance-selection.component.html',
  styleUrl: './performance-selection.component.scss'
})
export class PerformanceSelectionComponent implements OnInit {
  @Input() params: PerformanceParams;
  @Input() isRunning: boolean = false;

  @Input() userGroups: TreeNode[] = [];
  @Input() users: UserSelect[] = [];
  @Output() paramsChange = new EventEmitter<PerformanceParams>();
  @Output() isRunningChange = new EventEmitter<boolean>();

  readonly translatePrefix = 'report.';
  readonly unsubscribe$ = new Subject();
  readonly dateNow: Date = new Date();

  performanceForm = this.fb.group({
    users: this.fb.control<string[]>(['']),
    userGroups: this.fb.control<TreeNode[]>([{ label: '' }])
  });

  dates: Array<Date | undefined> = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.params) {
      this.performanceForm.patchValue({
        users: this.params.userIds,
        userGroups: this.params.groups
      });

      this.dates = [
        this.params.createdFrom ? new Date(this.params.createdFrom) : undefined,
        this.params.createdTo ? new Date(this.params.createdTo) : undefined
      ].filter(item => !!item);
    }
  }

  onRunReport() {
    const value = this.performanceForm.value;
    const createdFrom = this.dates?.[0] ? moment(this.dates?.[0]).toDate().toISOString() : undefined;
    const createdTo = this.dates?.[1] ? moment(this.dates?.[1]).toDate().toISOString() : undefined;

    this.params = {
      userIds: value.users || [],
      groups: value.userGroups || [],
      createdFrom,
      createdTo
    };
    this.paramsChange.emit(this.params);

    this.isRunning = true;
    this.isRunningChange.emit(this.isRunning);
  }

  getTreeNodeValue(value: TreeNode[]) {
    return value
      ?.filter(item => !!item.label)
      ?.map(item => item.label)
      .join(', ');
  }

  getUsersValue(value: UserSelect[]) {
    return value?.map(item => item.fullName).join(', ');
  }

  onReset() {
    this.performanceForm.patchValue({
      users: [''],
      userGroups: [{ label: '' }]
    });
    this.dates = [];
  }
}
