import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { APP_PERMISSION } from '@core/enum/permisson';
import { LinkDetail } from '@core/models/interfaces/admin-content/admin-content';
import { LinkService } from '@core/services/amin-content/link.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { AdminContentLinkEditComponent } from '../admin-content-link-edit/admin-content-link-edit.component';

@Component({
  selector: 'app-admin-content-link',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableModule,
    SearchInputComponent,
    ButtonComponent,
    CustomAvatarComponent,
    TooltipModule,
    CustomPaginatorComponent,
    AdminContentLinkEditComponent,
    EmptyTableComponent,
    NgxPermissionsModule
  ],
  templateUrl: './admin-content-link.component.html',
  styleUrl: './admin-content-link.component.scss'
})
export class AdminContentLinkComponent {
  readonly translatePrefix = 'admin-content.';
  readonly unsubscribe$ = new Subject();
  readonly Permissions = APP_PERMISSION;

  pagination = {
    page: 0,
    size: 10
  };

  linkDetail?: LinkDetail;
  isVisibleLinkEdit: boolean = false;

  linkState: { links: LinkDetail[]; totalLink: number; keyword: string; loading: boolean } = {
    links: [],
    totalLink: 0,
    keyword: '',
    loading: false
  };

  constructor(
    private linkService: LinkService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.fetchLinks();
  }

  onAddLink() {
    this.linkDetail = undefined;
    this.isVisibleLinkEdit = true;
  }

  fetchLinks() {
    this.linkState.loading = true;
    this.linkService
      .getLinkManagement({ page: this.pagination.page, size: this.pagination.size, keyword: this.linkState.keyword })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.linkState = {
            ...this.linkState,
            links: res?.data?.content,
            totalLink: res?.data?.total,
            loading: false
          };
        }
      });
  }

  onRefreshLinks() {
    this.isVisibleLinkEdit = false;
    this.pagination.page = 0;
    this.fetchLinks();
  }

  onEditLink(link: LinkDetail) {
    this.linkDetail = link;
    this.isVisibleLinkEdit = true;
  }

  onDeleteLink(link: LinkDetail) {
    this.confirmationService.confirm({
      message: this.translateService.instant(this.translatePrefix + 'confirm-delete', {
        name: link.title
      }),
      header: this.translateService.instant(this.translatePrefix + 'delete-link'),
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.delete'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => {
        this.linkService
          .deleteLink(link?.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                detail: this.translateService.instant(this.translatePrefix + 'delete-link-success')
              });
              this.linkState.links = [...this.linkState.links].filter(el => el.id !== link.id);
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                detail: this.translateService.instant(this.translatePrefix + 'delete-link-fail')
              });
            }
          });
      }
    });
  }

  onPinLink(link: LinkDetail) {
    this.linkService
      .pinLink(link.id, !link.isPin)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.fetchLinks();
          if (!link.isPin) {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(this.translatePrefix + 'pin-link-success')
            });
          } else {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(this.translatePrefix + 'unpin-link-success')
            });
          }
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail:
              err?.error?.error ||
              this.translateService.instant(this.translatePrefix + (!link.isPin ? 'pin-link-fail' : 'unpin-link-fail'))
          });
        }
      });
  }

  onSearch(keyword: string) {
    this.pagination.page = 0;
    this.linkState.keyword = keyword;
    this.fetchLinks();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
