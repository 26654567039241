import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UrlPattern } from '@core/constants/admin-content';
import { CreateLink, LinkDetail } from '@core/models/interfaces/admin-content/admin-content';
import { LinkService } from '@core/services/amin-content/link.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomDialogComponent } from '@shared/components/custom-dialog/custom-dialog.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-admin-content-link-edit',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    InputTextModule,
    ButtonComponent,
    CustomDialogComponent,
    FormControlComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './admin-content-link-edit.component.html',
  styleUrl: './admin-content-link-edit.component.scss'
})
export class AdminContentLinkEditComponent {
  @Input({ required: true }) visible: boolean = false;
  @Input() linkDetail?: LinkDetail;

  @Output() visibleChange = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  readonly translatePrefix = 'admin-content.';
  readonly unsubscribe$ = new Subject();

  linkForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private linkService: LinkService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnChanges(): void {
    this.initForm(this.linkDetail);
  }

  initForm(link?: LinkDetail) {
    this.linkForm = this.fb.group({
      title: [link?.title ?? null, [Validators.required]],
      url: [link?.url ?? null, [Validators.required, Validators.pattern(UrlPattern)]]
    });
  }

  onCancel() {
    this.visibleChange.emit(false);
    this.linkForm.reset();
  }

  onSaveLink() {
    this.linkForm.markAllAsTouched();
    const formData = this.linkForm.value;
    const body: CreateLink = {
      title: formData.title,
      url: formData.url
    };
    if (this.linkForm.invalid) return;
    const source = this.linkDetail?.id
      ? this.linkService?.updateLink(this.linkDetail?.id, body)
      : this.linkService.createLink(body);
    source.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: res => {
        this.onSuccess.emit();
        if (this.linkDetail?.id) {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(this.translatePrefix + 'update-link-success')
          });
        } else {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(this.translatePrefix + 'create-link-success')
          });
        }
      },
      error: err => {
        if (this.linkDetail?.id) {
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(this.translatePrefix + 'update-link-fail')
          });
        } else {
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(this.translatePrefix + 'create-link-fail')
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
