<div class="px-6 py-5 border rounded-md flex flex-col gap-1">
  <span class="uppercase text-neutral-600 font-medium truncate">{{ title }}</span>

  <span class="text-7 font-semibold">
    @switch (dataType) {
      @case ('CURRENCY') {
        <ng-container>{{ amount | currency }}</ng-container>
      }
      @case ('NUMBER') {
        <ng-container>{{ amount | number }}</ng-container>
      }
    }
  </span>

  <span class="flex items-center gap-1">
    @if (rate !== undefined && !isAll) {
      <div
        class="flex items-center"
        [ngClass]="{
          'text-green-600': rate > 0,
          'text-red-600': rate < 0,
          'text-neutral-600': rate === 0
        }">
        <i
          class="text-6 h-5 leading-5 mr-1 font-medium"
          [ngClass]="{
            'icon-wl-trending-up ': rate > 0,
            'icon-wl-trending-down ': rate < 0,
            hidden: rate === 0
          }"></i>
        <span class="font-medium">{{ rate }}%</span>
      </div>
    }
    <span class="text-neutral-600 truncate">{{ isAll ? ('common.all-time' | translate) : 'vs previous period' }}</span>
  </span>
</div>
