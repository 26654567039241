export enum DASHBOARD_TAB {
  LEAD = 'LEAD',
  POLICY = 'POLICY',
  PERFORMANCE = 'PERFORMANCE',
  USER = 'USER'
}

export enum LEAD_STATISTIC {
  TOTAL_LEADS_ALL_TIME = 'TOTAL_LEADS_ALL_TIME',
  CREATED_LEADS_ALL_TIME = 'CREATED_LEADS_ALL_TIME',
  ASSIGNED_LEADS_ALL_TIME = 'ASSIGNED_LEADS_ALL_TIME',

  TOTAL_LEADS_BY_TIME = 'TOTAL_LEADS_BY_TIME',
  CREATED_LEADS_BY_TIME = 'CREATED_LEADS_BY_TIME',
  ASSIGNED_LEADS_BY_TIME = 'ASSIGNED_LEADS_BY_TIME'
}

export enum USER_STATISTIC {
  TOTAL_USERS = 'TOTAL_USERS',
  ACTIVE_USERS = 'ACTIVE_USERS',
  HIDDEN_USERS = 'HIDDEN_USERS',
  INACTIVE_USERS = 'INACTIVE_USERS'
}

export enum POLICY_STATISTIC {
  TOTAL_SALES = 'TOTAL_SALES',
  NUMBER_OF_POLICIES = 'NUMBER_OF_POLICIES',
  TOTAL_PRODUCTION = 'TOTAL_PRODUCTION',
  PRODUCTION_BY_NEW_POLICIES = 'PRODUCTION_BY_NEW_POLICIES',
  CREATED_POLICIES = 'CREATED_POLICIES'
}

export enum PERFORMANCE_STATISTIC {
  TOTAL_LEADS = 'TOTAL_LEADS',
  TOTAL_NOTES = 'TOTAL_NOTES',
  TOTAL_TASKS = 'TOTAL_TASKS',
  TOTAL_APPOINTMENTS = 'TOTAL_APPOINTMENTS',
  TOTAL_CLICK_CALLS = 'TOTAL_CLICK_CALLS',
  TOTAL_ONLINE_HOURS = 'TOTAL_ONLINE_HOURS'
}

export enum LeadsByUsersSortFieldEnum {
  LEAD_TAKE_CARE = 'leadTakeCare',
  CREATED_LEAD = 'createdLead'
}
