import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { LANGUAGES } from '@core/constants/translate';
import { ReportService } from '@core/services/report.service';
import { ToastMessageComponent } from '@shared/components/toast-message/toast-message.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ToastMessageComponent, CommonModule, ConfirmDialogModule, NgxPermissionsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class AppComponent implements OnInit {
  private apiInterval: any;
  private INTERVAL = 300000; // 5 minutes = 300000ms

  constructor(
    private translateService: TranslateService,
    private primeNGConfig: PrimeNGConfig,
    private titleService: Title,
    private reportService: ReportService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(environment.APP_NAME);
    this.initLanguage();

    this.handleIntervalLogEvent();
  }

  initLanguage() {
    const language = localStorage.getItem('lang') as string;
    this.translateService.use(LANGUAGES.includes(language) ? language : 'en');

    this.translateService.get('PRIMENG').subscribe(res => {
      this.primeNGConfig.setTranslation(res);
    });
  }

  handleIntervalLogEvent() {
    const lastApiCall = localStorage.getItem('lastApiCall');
    const now = Date.now();

    if (!lastApiCall || now - parseInt(lastApiCall) > this.INTERVAL) {
      this.callApiLogEvent();
    }

    this.apiInterval = setInterval(() => {
      this.callApiLogEvent();
    }, this.INTERVAL);

    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  beforeUnloadHandler(): void {
    const now = Date.now();
    localStorage.setItem('lastApiCall', now.toString());
  }

  callApiLogEvent(): void {
    this.reportService.logEventReport('ONLINE').subscribe({
      next: () => {
        localStorage.setItem('lastApiCall', Date.now().toString());
      }
    });
  }

  ngOnDestroy(): void {
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }

    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }
}
