import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ChartData, ChartDataset } from 'chart.js';
import moment from 'moment';
import { ChartModule } from 'primeng/chart';
import { Subject, takeUntil } from 'rxjs';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';

import { DashboardColorOptions, GenderColorOptions, UserStatisticOptions } from '@core/constants/dashboard';
import { COLOR } from '@core/enum/color';
import { LeadsByUsersSortFieldEnum, USER_STATISTIC } from '@core/enum/dashboard';
import { ChartOption, LeadsByUsers } from '@core/models/interfaces/dashboard';
import { DashboardService } from '@core/services/dashboard.service';
import { StatisticComponent } from '@feature/dashboard/components/statistic/statistic.component';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { SortTableHeaderComponent } from '@shared/components/sort-table-header/sort-table-header.component';

@Component({
  selector: 'app-user-dashboard',
  standalone: true,
  imports: [
    ChartModule,
    TranslateModule,
    PaginatorModule,
    TableModule,
    CommonModule,
    DoughnutChartLegendComponent,
    StatisticComponent,
    CustomPaginatorComponent,
    EmptyTableComponent,
    SortTableHeaderComponent
  ],
  templateUrl: './user-dashboard.component.html',
  styleUrl: './user-dashboard.component.scss'
})
export class UserDashboardComponent implements OnChanges {
  @Input() rangeDates: Date[] = [];

  readonly unsubscribe$ = new Subject();
  readonly translatePrefix = 'dashboard.';
  readonly LeadsByUsersSortFieldEnum = LeadsByUsersSortFieldEnum;
  readonly doughnutChartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      }
    }
  };
  readonly departmentChartOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          maxTicksLimit: 7
        }
      }
    }
  };
  readonly usersByPositionChartOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          maxTicksLimit: 7
        }
      }
    }
  };

  userStatistics = UserStatisticOptions;
  usersByGender: ChartOption[] = [];
  usersByLocation: ChartOption[] = [];
  leadsByUsers: LeadsByUsers[] = [];

  sortField: string = '';
  sortOrder: number = 0;
  isLoading: boolean = false;
  totalLeadsByUsers: number = 0;
  leadsByUsersPagination = {
    page: 0,
    size: 10
  };

  usersByGenderChartData: ChartData;
  usersByLocationChartData: ChartData;
  userByDepartmentChartData: ChartData;
  usersByPositionChartData: ChartData;

  constructor(private dashboardService: DashboardService) {}

  ngOnChanges() {
    this.fetchStatisticData();
    this.fetchChartData();
    this.fetchLeadsByUsers();
  }

  get rangeDatesPrams() {
    const dateFrom = this.rangeDates?.[0]
      ? moment(this.rangeDates?.[0]).startOf('date').toDate().toISOString()
      : undefined;
    const dateTo = this.rangeDates?.[1] ? moment(this.rangeDates?.[1]).endOf('date').toDate().toISOString() : undefined;

    return { dateFrom, dateTo };
  }

  fetchStatisticData() {
    const { dateFrom, dateTo } = this.rangeDatesPrams;

    this.dashboardService
      .getUserStatisticalData({
        from: dateFrom,
        to: dateTo
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const data = res.data;
          for (let option of this.userStatistics) {
            switch (option.id) {
              case USER_STATISTIC.TOTAL_USERS: {
                option.rate = data.total?.rate;
                option.amount = data.total?.amount;
                break;
              }
              case USER_STATISTIC.ACTIVE_USERS: {
                option.rate = data.active?.rate;
                option.amount = data.active?.amount;
                break;
              }
              case USER_STATISTIC.HIDDEN_USERS: {
                option.rate = data.hidden?.rate;
                option.amount = data.hidden?.amount;
                break;
              }
              case USER_STATISTIC.INACTIVE_USERS: {
                option.rate = data.inactive?.rate;
                option.amount = data.inactive?.amount;
              }
            }
          }
        }
      });
  }

  fetchChartData() {
    const { dateFrom, dateTo } = this.rangeDatesPrams;

    this.dashboardService
      .getUserChartData({
        from: dateFrom,
        to: dateTo
      })
      .subscribe({
        next: res => {
          const genderChartData = res.data.gender;
          const departmentChartData = res.data.department;
          const positionChartData = res.data.position;
          const locationChartData = res.data.location;

          if (genderChartData) {
            this.usersByGender = genderChartData
              ?.sort((a, b) => b.amount - a.amount)
              ?.map((item, index) => {
                return {
                  label: item?.option?.name,
                  data: item.amount,
                  color: GenderColorOptions[index % 10]
                };
              });

            this.usersByGenderChartData = {
              labels: this.usersByGender.map(item => item.label),
              datasets: [
                {
                  data: this.usersByGender.map(item => item.data),
                  backgroundColor: this.usersByGender.map(item => item.color)
                }
              ]
            };
          }

          if (departmentChartData) {
            const datasets: ChartDataset[] = [
              {
                label: 'Male',
                data: [],
                backgroundColor: COLOR.BLUE,
                maxBarThickness: 40,
                borderRadius: 2
              },
              {
                label: 'Female',
                data: [],
                backgroundColor: COLOR.PINK,
                maxBarThickness: 40,
                borderRadius: 2
              },
              {
                label: 'Other',
                data: [],
                backgroundColor: COLOR.PURPLE,
                maxBarThickness: 40,
                borderRadius: 2
              }
            ];

            const labels = departmentChartData.map(item => item.name);
            const genders = departmentChartData.map(item => item.options);

            datasets[0].data = genders.flatMap(gender => {
              return gender.filter(item => item.option.code === 'MALE').map(item => item.amount);
            });
            datasets[1].data = genders.flatMap(gender => {
              return gender.filter(item => item.option.code === 'FEMALE').map(item => item.amount);
            });
            datasets[2].data = genders.flatMap(gender => {
              return gender.filter(item => item.option.code === 'OTHER').map(item => item.amount);
            });

            this.userByDepartmentChartData = {
              labels,
              datasets
            };
          }

          if (positionChartData) {
            const labels = positionChartData.map(item => item.option.name);
            const data = positionChartData.map(item => item.amount);
            this.usersByPositionChartData = {
              labels,
              datasets: [
                {
                  type: 'bar',
                  backgroundColor: COLOR.BLUE,
                  data,
                  maxBarThickness: 32,
                  borderRadius: 2
                }
              ]
            };
          }

          if (locationChartData) {
            this.usersByLocation = locationChartData
              ?.sort((a, b) => b.amount - a.amount)
              ?.map((item, index) => {
                return {
                  label: item?.option?.name,
                  data: item.amount,
                  color: DashboardColorOptions[index % 10].normal
                };
              });

            this.usersByLocationChartData = {
              labels: this.usersByLocation.map(item => item.label),
              datasets: [
                {
                  data: this.usersByLocation.map(item => item.data),
                  backgroundColor: this.usersByLocation.map(item => item.color)
                }
              ]
            };
          }
        }
      });
  }

  get isDataTable() {
    return this.leadsByUsers.length > 0;
  }

  fetchLeadsByUsers() {
    this.isLoading = true;
    const { dateFrom, dateTo } = this.rangeDatesPrams;
    const { page, size } = this.leadsByUsersPagination;

    const sorts = this.sortOrder > 0 ? 'DESC' : 'ASC';

    this.dashboardService
      .getLeadsByUsers({ from: dateFrom, to: dateTo, page, size, sorts: sorts })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.isLoading = false;
          this.leadsByUsers = res.data.content;
          this.totalLeadsByUsers = res.data.total;
        }
      });
  }

  onSortLeadsByUsers(event: { field: string; order: number }) {
    if (this.isLoading) return;
    this.sortField = event.field;
    this.sortOrder = event.order;

    this.fetchLeadsByUsers();
  }
}
