<div
  (click)="onClickNewNodeItem()"
  #targetElement
  class="bg-white p-3 flex gap-3 w-[220px] min-h-18 rounded-sm border border-gray-300 relative"
  [ngClass]="{
    'ring-2 ring-offset-1': nodeItem?.isFocus,
    ' !ring-blue-500 border-blue-500': !nodeItem?.isInvalid && nodeItem?.isFocus,
    ' ring-red-500 border-red-500  bg-red-100': nodeItem?.isInvalid
  }">
  @if (nodeItem?.isFocus && viewChart === ViewOrganization.EDIT) {
    <!-- Button Close -->
    <div
      class="bg-neutral-700 rounded-full absolute p-0.5 flex h-4 w-4 justify-center items-center -top-2 -right-2 cursor-pointer"
      (click)="onRemoveNodeItem(nodeItem)">
      <i class="icon-wl-close text-2 text-white leading-3"></i>
    </div>
  }

  <!-- Avatar -->
  @if (nodeItem.fullName) {
    <app-custom-avatar [imgId]="nodeItem?.avatar" [name]="nodeItem?.fullName ?? ''" size="md"></app-custom-avatar>
  } @else {
    <div class="p-1.5 bg-neutral-300 rounded-full h-8 w-8 flex items-center justify-center">
      <i class="icon-wl-user text-6"></i>
    </div>
  }

  <div class="flex flex-col items-start w-full gap-2 text-neutral-900">
    <div class="flex flex-col gap-0.5 w-full items-start">
      <!-- Full Name -->
      <span class="text-2 font-semibold">{{ nodeItem?.fullName ?? (translatePrefix + 'name' | translate) }}</span>
      <div class="flex flex-col items-start">
        <!-- Position -->
        <span class="text-2">{{ nodeItem?.position?.name ?? (translatePrefix + 'position' | translate) }}</span>

        <!-- Department -->
        <span class="text-2 text-neutral-600">{{
          nodeItem?.department?.name ?? (translatePrefix + 'department' | translate)
        }}</span>
      </div>
    </div>
    @if (viewChart === ViewOrganization.EDIT && nodeItem?.isFocus) {
      <!-- Tag Hiring -->
      <div class="flex gap-1.5 items-center" (click)="onFocusHiring($event)">
        <p-checkbox styleClass="!w-4.5 !h-4.5" [binary]="true" [(ngModel)]="nodeItem.isHiring" inputId="hiring" />
        <label class="text-2 font-medium" for="hiring">{{ translatePrefix + 'is-hiring' | translate }}</label>
      </div>
    }
  </div>
</div>
@if (nodeItem?.isInvalid) {
  <span class="text-2 text-red-500 italic">{{ translatePrefix + 'please-select-user' | translate }}</span>
}
<!-- Actions -->
@if (nodeItem?.isFocus && viewChart === ViewOrganization.EDIT && optionSelected) {
  @if (nodeItem.id && chartList[0]?.data?.id !== nodeItem?.id) {
    <!-- Button Same Level -->
    <div
      class="p-1 rounded-full bg-neutral-100 w-fit h-6 absolute flex justify-center items-center -right-32 top-8.5 cursor-pointer text-neutral-600 hover:bg-white"
      (click)="onActionAddNewNodeItem(NodeLevel.SAME_LEVEL)">
      <i class="icon-wl-plus text-4"></i
      ><span class="truncate text-2 font-medium">{{ translatePrefix + 'same-level-page' | translate }}</span>
    </div>
  }

  <!-- Button AddSub -->
  <div
    class="p-1 rounded-full bg-neutral-100 w-fit h-6 absolute flex justify-center items-center -bottom-9 left-18 cursor-pointer text-neutral-600 hover:bg-white"
    (click)="onActionAddNewNodeItem(NodeLevel.CHILD_LEVEL)">
    <i class="icon-wl-plus text-4"></i
    ><span class="truncate text-2 font-medium">{{ translatePrefix + 'add-sub' | translate }}</span>
  </div>
}

<app-org-chart-dropdown-option
  [userIdsSelected]="idsUserSelected"
  [(visible)]="isVisible"
  [(userState)]="userState"
  [appendTo]="targetElement"
  (optionSelected)="onSelectedOption($event)"></app-org-chart-dropdown-option>
