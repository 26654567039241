<div class="flex flex-col gap-3 border rounded-md p-4">
  <div class="flex items-center gap-2">
    <i
      class="icon-wl-arrow-drop-down text-6 cursor-pointer transition"
      [ngClass]="isExpanded ? '' : '-rotate-90'"
      (click)="onToggleExpanded()"></i>
    <span class="text-4 font-semibold"> {{ index }}. {{ report.name }} </span>
    <div
      class="h-6 min-w-6 px-1 w-fit flex items-center justify-center text-neutral-800 bg-neutral-300 rounded-sm font-medium">
      {{ report.data.total }}
    </div>
  </div>

  @if (isExpanded) {
    <p-table [value]="data" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr class="truncate">
          <th>{{ translatePrefix + 'policy-no' | translate }}</th>
          <th>{{ translatePrefix + 'lead' | translate }}</th>
          <th>{{ translatePrefix + 'created-date' | translate }}</th>
          <th>{{ translatePrefix + 'owner' | translate }}</th>
          <th>{{ translatePrefix + 'agent' | translate }}</th>
          <th>{{ translatePrefix + 'policy-status' | translate }}</th>
          <th>{{ translatePrefix + 'policy-tracking' | translate }}</th>
          <th>{{ translatePrefix + 'effective-date' | translate }}</th>
          <th>{{ translatePrefix + 'premium-mode' | translate }}</th>
          <th class="!text-right">
            {{ translatePrefix + 'premium-payment' | translate }}
          </th>
          <th class="!text-right">
            {{ translatePrefix + 'face-amount' | translate }}
          </th>
          <th>{{ translatePrefix + 'company' | translate }}</th>
          <th>{{ translatePrefix + 'product-type' | translate }}</th>
          <th>{{ translatePrefix + 'product-name' | translate }}</th>
          <th>{{ translatePrefix + 'contribution-years' | translate }}</th>
          <th>Commission team</th>
          <th>Commission percentage</th>
          <th>Comment</th>
          <th>{{ translatePrefix + 'created-by' | translate }}</th>
          <th class="!text-right" pFrozenColumn [frozen]="true" alignFrozen="right" frozenColStyle="right">
            {{ translatePrefix + 'premium-payment' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="truncate">
          <!-- Policy No -->
          <td>{{ item.number }}</td>

          <!-- Lead -->
          <td>{{ item.lead?.fullName || '--' }}</td>

          <!-- Created date -->
          <td>{{ item.createdAt | date: 'MM/dd/yyyy' }}</td>

          <!-- Owner -->
          <td>{{ item.owner?.fullName || '--' }}</td>

          <!-- Agent -->
          <td>{{ item.agent?.fullName || '--' }}</td>

          <!-- Policy status -->
          <td>{{ item.status?.name || '--' }}</td>

          <!-- Policy tracking -->
          <td>{{ item.policyTracking?.name || '--' }}</td>

          <!-- Effective date -->
          <td>{{ (item.effectiveDate | date: 'MM/dd/yyyy') || '--' }}</td>

          <!-- Premium mode -->
          <td>{{ item.mode?.name || '--' }}</td>

          <!-- Premium payment -->
          <td class="!text-right">{{ (item.payment | currency) || '--' }}</td>

          <!-- Face amount -->
          <td class="!text-right">{{ (item.faceAmount | currency) || '--' }}</td>

          <!-- Company -->
          <td>{{ item.product?.productType?.company?.name || '--' }}</td>

          <!-- Product type -->
          <td>{{ item.product?.productType?.name || '--' }}</td>

          <!-- Product name -->
          <td>{{ item.product?.name || '--' }}</td>

          <!-- Contribution years -->
          <td>{{ item.contributionYear || '--' }}</td>

          <!-- Commission team -->
          <td>{{ item.commissionTeam?.name || '--' }}</td>

          <!-- Commission percentage -->
          <td>{{ item.commissionPercentage?.name || '--' }}</td>

          <!-- Comment -->
          <td>
            <div class="max-w-[360px] truncate">{{ item.note || '--' }}</div>
          </td>

          <!-- Created by -->
          <td>{{ item.createdBy?.fullName }}</td>

          <!-- Target premium -->
          <td class="!text-right" pFrozenColumn [frozen]="true" alignFrozen="right" frozenColStyle="right">
            {{ item.target | currency }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="summary">
        <div class="px-3.5 border-t flex h-11 items-center justify-end bg-neutral-alpha-200A">
          {{ report.totalTarget | currency }}
        </div>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="20">
            <app-empty-table></app-empty-table>
          </td>
        </tr>
      </ng-template>
    </p-table>

    @if (report.data.total > 5) {
      <app-custom-paginator
        [(pagination)]="pagination"
        [totalRecords]="report.data.total"
        (onChange)="onPagination()"></app-custom-paginator>
    }
  }
</div>
