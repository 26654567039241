import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IdName } from '@core/models/interfaces/common';
import { TranslateModule } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { forkJoin, Subject } from 'rxjs';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MultiSelectModule } from 'primeng/multiselect';

import { ReportTabOptions } from '@core/constants/report';
import { ReportTabEnum } from '@core/enum/report';
import { Company } from '@core/models/interfaces/company';
import { LeadSource } from '@core/models/interfaces/lead';
import { PolicyStatusDetails } from '@core/models/interfaces/lead/lead-policy-status';
import { Product } from '@core/models/interfaces/product';
import { ProductType } from '@core/models/interfaces/product-type';
import { PerformanceParams, ProductDetailsReportParams } from '@core/models/interfaces/report';
import { UserDetail } from '@core/models/interfaces/user';
import { CompanyService } from '@core/services/company.service';
import { LeadSourceService } from '@core/services/lead-source.service';
import { PolicyStatusService } from '@core/services/policies/policy-status.service';
import { ProductTypeService } from '@core/services/product-type.service';
import { ProductService } from '@core/services/product.service';
import { TeamService } from '@core/services/team.service';
import { UserService } from '@core/services/user.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LeadReportComponent } from './components/lead-report/lead-report.component';
import { PerformanceReportComponent } from './components/performance-report/performance-report.component';
import { PerformanceSelectionComponent } from './components/performance-selection/performance-selection.component';
import { ProductDetailsReportComponent } from './components/product-details-report/product-details-report.component';
import { ProductionDetailsSelectionComponent } from './components/production-details-selection/production-details-selection.component';
import { APP_PERMISSION } from '@core/enum/permisson';
import { UserGroupService } from '@core/services/user-groups.service';
import { UserGroups } from '@core/models/interfaces/user-group';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SelectButtonModule,
    DropdownModule,
    TranslateModule,
    MenuModule,
    MultiSelectModule,
    NgxPermissionsModule,

    ProductionDetailsSelectionComponent,
    PerformanceSelectionComponent,
    LeadReportComponent,
    ProductDetailsReportComponent,
    ButtonComponent,
    PerformanceReportComponent
  ],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent implements OnInit, OnDestroy {
  readonly Permission = APP_PERMISSION;
  readonly ReportTabEnum = ReportTabEnum;
  readonly translatePrefix = 'report.';
  readonly unsubscribe$ = new Subject();

  tabActive: ReportTabEnum;

  tabOptions = ReportTabOptions;
  productDetailsReportParams: ProductDetailsReportParams;
  performanceReportParams: PerformanceParams;
  leadReportParams: PerformanceParams;

  isCanRunReportProductDetails = true;

  isRunningProductDetailsReport = false;
  isRunningPerformanceReport = false;
  isRunningLeadReport = false;

  commissionTeams: IdName[] = [];
  companies: Company[] = [];
  productTypes: ProductType[] = [];
  products: Product[] = [];
  users: UserDetail[] = [];
  sources: TreeNode[] = [];
  policyStatuses: TreeNode[] = [];
  userGroups: TreeNode[] = [];

  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private companyService: CompanyService,
    private productTypeService: ProductTypeService,
    private productService: ProductService,
    private sourceService: LeadSourceService,
    private policyStatusService: PolicyStatusService,
    private userGroupService: UserGroupService
  ) {}

  ngOnInit(): void {
    this.tabActive = ReportTabEnum.PRODUCT;

    forkJoin([
      this.teamService.getTeams(),
      this.companyService.getCompanies(),
      this.productTypeService.getProductTypes({
        size: 100
      }),
      this.productService.getProducts({
        size: 100
      }),
      this.userService.getUsers({
        size: 999
      }),
      this.sourceService.getSources(),
      this.policyStatusService.getPagePolicyStatus({
        size: 100
      }),
      this.userGroupService.getGroups()
    ]).subscribe({
      next: results => {
        const [teamRes, companyRes, productTypeRes, productRes, userRes, sourcesRes, policyStatusRes, userGroupRes] =
          results;

        this.commissionTeams = teamRes.data.content;
        this.companies = companyRes.data.content;
        this.productTypes = productTypeRes.data.content;
        this.products = productRes.data.content;
        this.users = userRes.data.content;
        this.sources = sourcesRes.data.content.map(item => this.convertToTreeNode(item));
        this.policyStatuses = policyStatusRes.data.content.map(item => this.convertToTreeNode(item));
        this.userGroups = userGroupRes.data.content.map(item => this.convertUserGroupToTreeNode(item));
      }
    });
  }

  convertToTreeNode(item: LeadSource | PolicyStatusDetails): TreeNode {
    let treeNode: TreeNode = {
      key: item.id,
      label: item.name,
      data: {
        id: item.id,
        code: item.code
      },
      children: item.children && item.children.length > 0 ? item.children.map(this.convertToTreeNode) : [],
      leaf: !item.children || item.children.length === 0
    };

    return treeNode;
  }

  convertUserGroupToTreeNode(item: UserGroups): TreeNode {
    let treeNode: TreeNode = {
      key: item.id,
      label: item.name,
      data: {
        id: item.id
      },
      children: item.subGroups && item.subGroups.length > 0 ? item.subGroups.map(this.convertUserGroupToTreeNode) : [],
      leaf: !item.subGroups || item.subGroups.length === 0
    };

    return treeNode;
  }

  checkPermission(event: APP_PERMISSION, isHasPermission: boolean) {
    const tab = ReportTabOptions.find(item => item.permission === event);
    if (!tab) return;

    const isExistTab = this.tabOptions.find(item => item.permission === event);

    if (isHasPermission) {
      if (!isExistTab) {
        this.tabOptions = [...this.tabOptions, tab].sort((a, b) => a.order - b.order);
      }
    } else {
      this.tabOptions = [...this.tabOptions].filter(item => item.permission !== event);
    }

    this.tabActive = this.tabOptions[0].value;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
