import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-statistic',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './statistic.component.html',
  styleUrl: './statistic.component.scss'
})
export class StatisticComponent {
  @Input() title: string = '';
  @Input() amount: number = 0;
  @Input() isAll: boolean = false;
  @Input() dataType: 'CURRENCY' | 'NUMBER' | string = 'NUMBER';
  @Input() rate?: number = 0;

  getAbsInfluence(value?: number) {
    if (!value) return '';
    return Math.abs(value);
  }
}
