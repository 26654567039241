export const PoliciesBySourceMockData = [
  {
    label: 'Funnel',
    data: 10,
    color: '#3B82F6'
  },
  {
    label: 'Funnel referral',
    data: 15,
    color: '#06B6D4'
  },
  {
    label: 'Cold call',
    data: 8,
    color: '#8B5CF6'
  },
  {
    label: 'Cold call referral',
    data: 6,
    color: '#F43F5E'
  }
];
