<div class="flex flex-col gap-4 px-8">
  <div class="flex items-center gap-3 justify-between">
    <app-search-input inputClass="h-8 w-[320px]" [debounceTime]="600" (onSearch)="onSearch($event)"></app-search-input>
    <app-button
      *ngxPermissionsOnly="Permissions.LINK_ADD_EDIT"
      icon="icon-wl-plus-circle"
      iconPosition="left"
      [label]="'action.add' | translate"
      size="sm"
      (onClick)="onAddLink()"></app-button>
  </div>

  <!-- Table -->
  <p-table [value]="linkState.links" [loading]="linkState.loading">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ translatePrefix + 'title' | translate }}</th>
        <th>{{ translatePrefix + 'url' | translate }}</th>
        <th>{{ translatePrefix + 'created-by' | translate }}</th>
        <th>{{ translatePrefix + 'created-date' | translate }}</th>
        <th class="w-22"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr class="h-[128px]">
        <td class="!px-3" [colSpan]="6"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-link>
      <tr>
        <!-- Title -->
        <td>
          <div class="flex items-center gap-2 max-w-[720px] truncate">
            <span class="flex-1">{{ link.title ?? '--' }}</span>
            <div
              *ngxPermissionsOnly="Permissions.LINK_PIN"
              (click)="onPinLink(link)"
              [pTooltip]="translatePrefix + (!link?.isPin ? 'pin' : 'un-pin') | translate"
              tooltipPosition="top"
              class="cursor-pointer hover:!opacity-100 w-6 h-6 flex justify-center items-center shadow-sm hover:border hover:border-neutral-300 hover:bg-neutral-200 rounded"
              [ngClass]="{ 'opacity-0': !link.isPin }">
              @if (link?.isPin) {
                <img src="assets/images/icons/icon-pin-solid-black.svg" alt="pin" class="w-4 h-4 leading-6" />
              } @else {
                <i class="icon-wl-pin text-4"></i>
              }
            </div>
          </div>
        </td>

        <!-- URL -->
        <td>{{ link.url ?? '--' }}</td>

        <!-- Created by -->
        <td>
          <div class="flex gap-2 items-center">
            <app-custom-avatar
              [imgId]="link?.createdBy?.avatar"
              [name]="link?.createdBy?.fullName ?? ''"></app-custom-avatar>

            <span>{{ link?.createdBy?.fullName ?? '--' }}</span>
          </div>
        </td>

        <!-- Created date -->
        <td>{{ link?.createdAt | date: 'MM/dd/yyyy HH:ss' }}</td>

        <!-- Action -->
        <td>
          <div class="flex items-center">
            <div
              *ngxPermissionsOnly="Permissions.LINK_ADD_EDIT"
              (click)="onEditLink(link)"
              class="flex justify-center items-center w-9 h-9 hover:bg-neutral-200 hover:border hover:border-neutral-300 rounded cursor-pointer">
              <i class="icon-wl-edit text-6"></i>
            </div>
            <div
              *ngxPermissionsOnly="Permissions.LINK_DELETE"
              (click)="onDeleteLink(link)"
              class="flex justify-center items-center w-9 h-9 hover:bg-neutral-200 hover:border hover:border-neutral-300 rounded cursor-pointer">
              <i class="icon-wl-delete text-6 text-red-500"></i>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="5">
          <app-empty-table />
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Paginator -->
<div class="py-3 px-8">
  <app-custom-paginator
    [(pagination)]="pagination"
    [totalRecords]="linkState.totalLink"
    (onChange)="fetchLinks()"></app-custom-paginator>
</div>

<app-admin-content-link-edit
  [linkDetail]="linkDetail"
  [(visible)]="isVisibleLinkEdit"
  (onSuccess)="onRefreshLinks()"></app-admin-content-link-edit>
