<div class="flex flex-col gap-6">
  <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6">
    @for (statistic of userStatistics; track $index) {
      <div class="flex-1">
        <app-statistic
          [title]="statistic.title"
          [amount]="statistic.amount"
          [dataType]="statistic.dataType"
          [rate]="statistic.rate"
          [isAll]="statistic.isAll"></app-statistic>
      </div>
    }
  </div>

  <div class="grid grid-cols-3 gap-6">
    <!-- Users by gender -->
    <div class="flex flex-col justify-between rounded-md border px-6 py-5">
      <div>
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'user-by-gender' | translate }}</h2>
        <div class="pt-7 pb-13 flex justify-center">
          <p-chart
            width="200px"
            height="200px"
            type="doughnut"
            [data]="usersByGenderChartData"
            [options]="doughnutChartOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="usersByGender"></app-doughnut-chart-legend>
    </div>

    <!-- Users by department -->
    <div class="col-span-2 flex flex-col justify-between rounded-md border px-6 py-5">
      <div>
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'users-by-department' | translate }}</h2>
        <div class="pt-2 flex justify-center">
          <p-chart
            class="w-full"
            height="272px"
            width="100%"
            type="bar"
            [data]="userByDepartmentChartData"
            [options]="departmentChartOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="usersByGender"></app-doughnut-chart-legend>
    </div>

    <!-- Users by position -->
    <div class="col-span-2 flex flex-col justify-between rounded-md border px-6 py-5">
      <div>
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'users-by-position' | translate }}</h2>
        <div class="pt-2 flex justify-center">
          <p-chart
            class="w-full"
            height="308px"
            width="100%"
            type="bar"
            [data]="usersByPositionChartData"
            [options]="usersByPositionChartOptions"></p-chart>
        </div>
      </div>
    </div>

    <!-- Users by location -->
    <div class="flex flex-col justify-between rounded-md border px-6 py-5">
      <div>
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'users-by-location' | translate }}</h2>
        <div class="pt-7 pb-13 flex justify-center">
          <p-chart
            width="200px"
            height="200px"
            type="doughnut"
            [data]="usersByLocationChartData"
            [options]="doughnutChartOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="usersByLocation"></app-doughnut-chart-legend>
    </div>
  </div>

  <!-- Leads by users -->
  <div class="card">
    <p-table
      [value]="leadsByUsers"
      [tableStyle]="{ 'min-width': '50rem' }"
      [lazy]="true"
      (onSort)="onSortLeadsByUsers($event)"
      [loading]="isLoading"
      [customSort]="true"
      [styleClass]="!isDataTable ? 'min-h-[240px]' : ''">
      <ng-template pTemplate="caption">
        <h2 class="flex items-center py-1.5 text-4 font-semibold">
          {{ translatePrefix + 'leads-by-users' | translate }}
        </h2>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="!text-center">#</th>
          <th>{{ translatePrefix + 'user-name' | translate }}</th>
          <th>{{ translatePrefix + 'groups' | translate }}</th>
          <th class="!text-center">
            {{ translatePrefix + 'created-lead' | translate }}
          </th>
          <th class="!text-center" [pSortableColumn]="LeadsByUsersSortFieldEnum.LEAD_TAKE_CARE">
            <app-sort-table-header
              [label]="translatePrefix + 'lead-take-care' | translate"
              [field]="LeadsByUsersSortFieldEnum.LEAD_TAKE_CARE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-index="rowIndex">
        <tr>
          <td class="!text-center">
            <div
              class="w-7 h-7 rounded-full flex items-center justify-center mx-auto"
              [ngClass]="{
                'text-white font-semibold': index <= 2,
                'bg-blue-500': index === 0,
                'bg-orange-500': index === 1,
                'bg-neutral-600': index === 2
              }">
              {{ index + 1 }}
            </div>
          </td>
          <td>{{ item.user?.fullName }}</td>
          <td>
            @for (group of item.user?.groups; track $index) {
              @if ($index < item.user?.groups?.length - 1) {
                {{ group.name }},
              } @else {
                {{ group.name }}
              }
            }
          </td>
          <td class="!text-center">{{ item.createdLead }}</td>
          <td class="!text-center">{{ item.leadTakeCare }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="5">
            <app-empty-table />
          </td>
        </tr>
      </ng-template>

      <app-custom-paginator
        [(pagination)]="leadsByUsersPagination"
        [totalRecords]="totalLeadsByUsers"
        (onChange)="fetchLeadsByUsers()"></app-custom-paginator>
    </p-table>
  </div>
</div>
