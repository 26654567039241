@if (isFetched || leadReport.length) {
  <div class="flex flex-col gap-3">
    <!-- Caption -->
    <div class="flex items-center justify-between">
      <h3 class="font-semibold text-4">
        {{ translatePrefix + 'report' | translate }} {{ leadReportDate | date: 'MM/dd/yyyy HH:mm' }}
      </h3>

      <div *ngxPermissionsOnly="Permission.REPORT_EXPORT">
        <app-button
          (onClick)="exportMenu.toggle($event)"
          label="Export"
          size="sm"
          icon="icon-wl-upload"
          iconPosition="left"
          [isDropdown]="true"
          variant="outline-secondary"></app-button>
        <p-menu #exportMenu [model]="exportOptions" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <div class="flex items-center gap-2 p-2 cursor-pointer">
              <img class="w-5 h-5" [src]="item.img" [alt]="item.title" />
              <span>{{ item.title }}</span>
            </div>
          </ng-template>
        </p-menu>
      </div>
    </div>

    <p-table
      [value]="leadReport"
      [scrollable]="true"
      (onSort)="onSort($event)"
      [lazy]="true"
      [customSort]="true"
      [loading]="isLoading">
      <ng-template pTemplate="header">
        <tr class="truncate">
          <th pFrozenColumn [frozen]="true" alignFrozen="left" frozenColStyle="left">
            <div class="flex items-center">
              <div class="w-12">{{ 'report.no.' | translate }}</div>
              <div>{{ translatePrefix + 'user' | translate }}</div>
            </div>
          </th>

          <!-- Notes -->
          <th class="!text-right" [pSortableColumn]="LeadSortFieldEnum.LEAD_MOVED">
            <app-sort-table-header
              [label]="translatePrefix + 'total-lead-moved' | translate"
              [field]="LeadSortFieldEnum.LEAD_MOVED"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Click Calls -->
          <th class="!text-right" [pSortableColumn]="LeadSortFieldEnum.LEAD_TAKE_CARE">
            <app-sort-table-header
              [label]="translatePrefix + 'total-leads-take-care' | translate"
              [field]="LeadSortFieldEnum.LEAD_TAKE_CARE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Updated Leads -->
          <th class="!text-right" [pSortableColumn]="LeadSortFieldEnum.LEAD_CREATED">
            <app-sort-table-header
              [label]="translatePrefix + 'total-leads-created' | translate"
              [field]="LeadSortFieldEnum.LEAD_CREATED"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- No Updated Leads -->
          <th class="!text-right" [pSortableColumn]="LeadSortFieldEnum.CUSTOMER">
            <app-sort-table-header
              [label]="translatePrefix + 'customer' | translate"
              [field]="LeadSortFieldEnum.CUSTOMER"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Tasks -->
          <th class="!text-right" [pSortableColumn]="LeadSortFieldEnum.PROSPECT">
            <app-sort-table-header
              [label]="translatePrefix + 'prospect' | translate"
              [field]="LeadSortFieldEnum.PROSPECT"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Appointments -->
          <th class="!text-right" [pSortableColumn]="LeadSortFieldEnum.HIDDEN">
            <app-sort-table-header
              [label]="translatePrefix + 'hidden' | translate"
              [field]="LeadSortFieldEnum.HIDDEN"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr class="truncate">
          <!-- No. -->
          <td pFrozenColumn [frozen]="true" alignFrozen="left" frozenColStyle="left">
            <div class="flex items-center">
              <div class="w-12">{{ i + 1 }}</div>
              <div>{{ item.fullName }}</div>
            </div>
          </td>

          <!-- Total lead moved -->
          <td class="!text-right !pr-7">{{ item.leadMoved }}</td>

          <!-- Total leads take care -->
          <td class="!text-right !pr-7">{{ item.leadTakeCare }}</td>

          <!-- Total leads created -->
          <td class="!text-right !pr-7">{{ item.leadCreated }}</td>

          <!-- Customer -->
          <td class="!text-right !pr-7">{{ item.customer }}</td>

          <!-- Prospect -->
          <td class="!text-right !pr-7">{{ item.prospect }}</td>

          <!-- Hidden -->
          <td class="!text-right !pr-7">{{ item.hidden }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="20">
            <app-empty-table></app-empty-table>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-custom-paginator
      [(pagination)]="pagination"
      [totalRecords]="totalReports"
      (onChange)="onPagination()"></app-custom-paginator>
  </div>
}
