<div class="flex flex-col gap-4 p-5 border rounded-md">
  <h2 class="font-semibold text-4">{{ translatePrefix + 'report-production-details' | translate }}</h2>

  <!-- FORM -->
  <form class="flex flex-col gap-5" [formGroup]="productionDetailsForm">
    <!-- OBJECTIVE SELECTION -->
    <div class="flex flex-col gap-2">
      <h3 class="text-2 font-medium uppercase">{{ translatePrefix + 'objective-selection' | translate }}</h3>
      <div class="grid grid-cols-3 gap-4">
        <app-form-control
          [control]="productionDetailsForm.get('commissionTeams')"
          [floatLabel]="true"
          [label]="translatePrefix + 'commission-team' | translate">
          <p-multiSelect
            input
            [filter]="false"
            [options]="commissionTeams"
            optionLabel="name"
            optionValue="id"
            formControlName="commissionTeams"
            dropdownIcon="icon-wl-arrow-drop-down">
            <ng-template let-value pTemplate="selectedItems">
              <span>{{ getCommissionTeamsValue(value) || (translatePrefix + 'all-groups' | translate) }}</span>
            </ng-template>
          </p-multiSelect>
        </app-form-control>
      </div>
    </div>

    <!-- FIELD SELECTION -->
    <div class="flex flex-col gap-2">
      <h3 class="text-2 font-medium uppercase">{{ translatePrefix + 'field-selection' | translate }}</h3>
      <div class="grid grid-cols-3 gap-4">
        <!-- Source - Sub source -->
        <app-form-control
          [control]="productionDetailsForm.get('sources')"
          [floatLabel]="true"
          [label]="translatePrefix + 'source-sub-source' | translate">
          <p-treeSelect
            input
            [filter]="false"
            inputId="id"
            [options]="sources"
            scrollHeight="360px"
            containerStyleClass="w-full"
            formControlName="sources"
            dropdownIcon="icon-wl-arrow-drop-down"
            selectionMode="checkbox">
            <ng-template pTemplate="triggericon">
              <i class="icon-wl-arrow-drop-down text-6 text-neutral-1000 mr-1.5"></i>
            </ng-template>

            <ng-template let-value pTemplate="value">
              <span>{{ getTreeNodeValue(value) || ('common.all' | translate) }}</span>
            </ng-template>

            <ng-template pTemplate="itemtogglericon" let-implicit>
              <i
                class="text-6 text-neutral-1000"
                [ngClass]="implicit ? 'icon-wl-arrow-drop-down' : 'icon-wl-arrow-drop-right'"></i>
            </ng-template>
          </p-treeSelect>
        </app-form-control>

        <!-- Policy status -->
        <app-form-control
          [control]="productionDetailsForm.get('policyStatuses')"
          [floatLabel]="true"
          [label]="translatePrefix + 'policy-status-detail' | translate">
          <p-treeSelect
            input
            [filter]="false"
            [options]="policyStatuses"
            scrollHeight="360px"
            containerStyleClass="w-full"
            formControlName="policyStatuses"
            dropdownIcon="icon-wl-arrow-drop-down"
            selectionMode="checkbox">
            <ng-template pTemplate="triggericon">
              <i class="icon-wl-arrow-drop-down text-6 text-neutral-1000 mr-1.5"></i>
            </ng-template>

            <ng-template pTemplate="itemtogglericon" let-implicit>
              <i
                class="text-6 text-neutral-1000"
                [ngClass]="implicit ? 'icon-wl-arrow-drop-down' : 'icon-wl-arrow-drop-right'"></i>
            </ng-template>

            <ng-template let-value pTemplate="value">
              <span>{{ getTreeNodeValue(value) || (translatePrefix + 'all-statuses' | translate) }}</span>
            </ng-template>
          </p-treeSelect>
        </app-form-control>

        <!-- Company -->
        <app-form-control
          [control]="productionDetailsForm.get('companies')"
          [floatLabel]="true"
          [label]="translatePrefix + 'company' | translate">
          <p-multiSelect
            input
            [filter]="false"
            [options]="companies"
            optionLabel="name"
            optionValue="id"
            formControlName="companies"
            dropdownIcon="icon-wl-arrow-drop-down"
            (onChange)="onChangeCompany()">
            <ng-template let-value pTemplate="selectedItems">
              <span>{{ getCompaniesValue(value) || (translatePrefix + 'all-companies' | translate) }}</span>
            </ng-template>
          </p-multiSelect>
        </app-form-control>

        <!-- Product type -->
        <app-form-control
          [control]="productionDetailsForm.get('productTypes')"
          [floatLabel]="true"
          [label]="translatePrefix + 'product-type' | translate">
          <p-multiSelect
            input
            [filter]="false"
            [options]="productTypeOptions"
            optionLabel="name"
            optionValue="id"
            formControlName="productTypes"
            dropdownIcon="icon-wl-arrow-drop-down"
            (onChange)="onChangeProductType()">
            <ng-template let-value pTemplate="selectedItems">
              <span>{{ getProductTypesValue(value) || (translatePrefix + 'all-types' | translate) }}</span>
            </ng-template>
          </p-multiSelect>
        </app-form-control>

        <!-- Product name -->
        <app-form-control
          [control]="productionDetailsForm.get('productNames')"
          [floatLabel]="true"
          [label]="translatePrefix + 'product-name' | translate">
          <p-multiSelect
            input
            [filter]="false"
            [options]="productOptions"
            optionLabel="name"
            optionValue="id"
            formControlName="productNames"
            dropdownIcon="icon-wl-arrow-drop-down">
            <ng-template let-value pTemplate="selectedItems">
              <span>{{ getProductsValue(value) || (translatePrefix + 'all-products' | translate) }}</span>
            </ng-template>
          </p-multiSelect>
        </app-form-control>

        <!-- Commission owners -->
        <app-form-control
          [control]="productionDetailsForm.get('commissionOwners')"
          [floatLabel]="true"
          [label]="translatePrefix + 'commission-owner' | translate">
          <p-multiSelect
            input
            [filter]="true"
            [options]="users"
            optionValue="id"
            optionLabel="fullName"
            formControlName="commissionOwners"
            [virtualScroll]="true"
            [virtualScrollItemSize]="36"
            dropdownIcon="icon-wl-arrow-drop-down">
            <ng-template let-value pTemplate="selectedItems">
              <span>{{ getCommissionsValue(value) || (translatePrefix + 'all-owners' | translate) }}</span>
            </ng-template>
          </p-multiSelect>
        </app-form-control>

        <!-- Commission agents -->
        <app-form-control
          [control]="productionDetailsForm.get('commissionAgents')"
          [floatLabel]="true"
          [label]="translatePrefix + 'commission-agent' | translate">
          <p-multiSelect
            input
            [filter]="true"
            [options]="users"
            optionValue="id"
            optionLabel="fullName"
            formControlName="commissionAgents"
            [virtualScroll]="true"
            [virtualScrollItemSize]="36"
            dropdownIcon="icon-wl-arrow-drop-down">
            <ng-template let-value pTemplate="selectedItems">
              <span>{{ getCommissionsValue(value) || (translatePrefix + 'all-agents' | translate) }}</span>
            </ng-template>
          </p-multiSelect>
        </app-form-control>

        <!-- Group by -->
        <app-form-control
          [control]="productionDetailsForm.get('groupBy')"
          [floatLabel]="true"
          [label]="translatePrefix + 'group-by' | translate">
          <p-dropdown
            input
            [filter]="false"
            [options]="ProductDetailsGroupBys"
            styleClass="w-full h-12"
            optionLabel="title"
            optionValue="value"
            formControlName="groupBy"
            dropdownIcon="icon-wl-arrow-drop-down"></p-dropdown>
        </app-form-control>
      </div>
    </div>

    <!-- DATE -->
    <div class="flex flex-col gap-2">
      <h3 class="text-2 font-medium uppercase">{{ translatePrefix + 'date' | translate }}</h3>
      <div class="grid grid-cols-3 gap-4">
        <app-custom-calendar
          [title]="translatePrefix + 'created-date' | translate"
          [(datesSelected)]="createdDates"
          [isInput]="true"></app-custom-calendar>

        <app-custom-calendar
          [title]="translatePrefix + 'effective-date' | translate"
          [(datesSelected)]="effectiveDates"
          [isInput]="true"></app-custom-calendar>
      </div>
    </div>
  </form>

  <!-- ACTIONS -->
  <div class="flex items-center gap-2 justify-end">
    @if (productionDetailsForm.valid) {
      <app-button
        variant="ghost-primary"
        [label]="'action.reset' | translate"
        size="sm"
        (onClick)="onReset()"></app-button>
    }

    <app-button
      [label]="translatePrefix + 'run-report' | translate"
      size="sm"
      [disabled]="productionDetailsForm.invalid"
      [loading]="isRunning"
      (onClick)="onRunReport()"></app-button>
  </div>
</div>
