import {
  DASHBOARD_TAB,
  LEAD_STATISTIC,
  PERFORMANCE_STATISTIC,
  POLICY_STATISTIC,
  USER_STATISTIC
} from '@core/enum/dashboard';
import { APP_PERMISSION } from '@core/enum/permisson';
import { COLOR, COLOR_BOLD, COLOR_LIGHT } from '@core/enum/color';
import { GENDER } from '@core/models/interfaces/common';

export const DashboardColorOptions = [
  {
    light: COLOR_LIGHT.BLUE,
    normal: COLOR.BLUE,
    bold: COLOR_BOLD.BLUE
  },
  {
    light: COLOR_LIGHT.CYAN,
    normal: COLOR.CYAN,
    bold: COLOR_BOLD.CYAN
  },
  {
    light: COLOR_LIGHT.PURPLE,
    normal: COLOR.PURPLE,
    bold: COLOR_BOLD.PURPLE
  },
  {
    light: COLOR_LIGHT.GREEN,
    normal: COLOR.GREEN,
    bold: COLOR_BOLD.GREEN
  },
  {
    light: COLOR_LIGHT.PINK,
    normal: COLOR.PINK,
    bold: COLOR_BOLD.PINK
  },
  {
    light: COLOR_LIGHT.RED,
    normal: COLOR.RED,
    bold: COLOR_BOLD.RED
  },
  {
    light: COLOR_LIGHT.ORANGE,
    normal: COLOR.ORANGE,
    bold: COLOR_BOLD.ORANGE
  },
  {
    light: COLOR_LIGHT.YELLOW,
    normal: COLOR.YELLOW,
    bold: COLOR_BOLD.YELLOW
  },
  {
    light: COLOR_LIGHT.ROSE,
    normal: COLOR.ROSE,
    bold: COLOR_BOLD.ROSE
  },
  {
    light: COLOR_LIGHT.GRAY,
    normal: COLOR.GRAY,
    bold: COLOR_BOLD.GRAY
  }
];

export const GenderColorOptions = [COLOR.BLUE, COLOR.PINK, COLOR.PURPLE];

export const DashboardTabOptions = [
  {
    title: 'LEAD',
    value: DASHBOARD_TAB.LEAD,
    permission: APP_PERMISSION.DASHBOARD_LEAD_VIEW
  },
  {
    title: 'POLICY',
    value: DASHBOARD_TAB.POLICY,
    permission: APP_PERMISSION.DASHBOARD_POLICY_VIEW
  },
  {
    title: 'PERFORMANCE',
    value: DASHBOARD_TAB.PERFORMANCE,
    permission: APP_PERMISSION.DASHBOARD_PERFORMANCE_VIEW
  },
  {
    title: 'USER',
    value: DASHBOARD_TAB.USER,
    permission: APP_PERMISSION.DASHBOARD_USER_VIEW
  }
];

export const GenderDashboardOptions = [
  {
    title: 'Male',
    value: GENDER.MALE,
    color: '#3B82F6'
  },
  {
    title: 'Female',
    value: GENDER.FEMALE,
    color: '#EC4899'
  },
  {
    title: 'Other',
    value: GENDER.OTHER,
    color: '#8B5CF6'
  }
];

export const LeadStatisticOptions = [
  // All time
  {
    id: LEAD_STATISTIC.TOTAL_LEADS_ALL_TIME,
    title: 'TOTAL LEADS',
    amount: 0,
    dataType: 'NUMBER',
    isAll: true
  },
  { id: LEAD_STATISTIC.CREATED_LEADS_ALL_TIME, title: 'CREATED LEADS', amount: 0, dataType: 'NUMBER', isAll: true },
  { id: LEAD_STATISTIC.ASSIGNED_LEADS_ALL_TIME, title: 'ASSIGNED LEADS', amount: 0, dataType: 'NUMBER', isAll: true },

  // By time
  {
    id: LEAD_STATISTIC.TOTAL_LEADS_BY_TIME,
    title: 'TOTAL LEADS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: LEAD_STATISTIC.CREATED_LEADS_BY_TIME,
    title: 'CREATED LEADS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: LEAD_STATISTIC.ASSIGNED_LEADS_BY_TIME,
    title: 'ASSIGNED LEADS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  }
];

export const UserStatisticOptions = [
  {
    id: USER_STATISTIC.TOTAL_USERS,
    title: 'TOTAL USERS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: USER_STATISTIC.ACTIVE_USERS,
    title: 'ACTIVE USERS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  { id: USER_STATISTIC.HIDDEN_USERS, title: 'HIDDEN USERS', amount: 0, dataType: 'NUMBER', rate: 0, isAll: false },
  { id: USER_STATISTIC.INACTIVE_USERS, title: 'INACTIVE USERS', amount: 0, dataType: 'NUMBER', rate: 0, isAll: false }
];

export const PolicyStatisticOptions = [
  {
    id: POLICY_STATISTIC.TOTAL_SALES,
    title: 'TOTAL SALES',
    amount: 0,
    dataType: 'CURRENCY',
    isAll: true
  },
  {
    id: POLICY_STATISTIC.NUMBER_OF_POLICIES,
    title: 'NUMBER OF POLICIES',
    amount: 0,
    dataType: 'NUMBER',
    isAll: true
  },
  {
    id: POLICY_STATISTIC.TOTAL_PRODUCTION,
    title: 'TOTAL PRODUCTION',
    amount: 0,
    dataType: 'CURRENCY',
    isAll: true
  },
  {
    id: POLICY_STATISTIC.PRODUCTION_BY_NEW_POLICIES,
    title: 'PRODUCTION BY NEW POLICIES',
    amount: 0,
    dataType: 'CURRENCY',
    rate: 0,
    isAll: false
  },
  {
    id: POLICY_STATISTIC.CREATED_POLICIES,
    title: 'CREATED POLICIES',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  }
];

export const PerformanceStatisticOptions = [
  {
    id: PERFORMANCE_STATISTIC.TOTAL_LEADS,
    title: 'TOTAL LEADS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: PERFORMANCE_STATISTIC.TOTAL_NOTES,
    title: 'TOTAL NOTES',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: PERFORMANCE_STATISTIC.TOTAL_TASKS,
    title: 'TOTAL TASKS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: PERFORMANCE_STATISTIC.TOTAL_APPOINTMENTS,
    title: 'TOTAL APPOINTMENTS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: PERFORMANCE_STATISTIC.TOTAL_CLICK_CALLS,
    title: 'TOTAL CLICK CALLS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  },
  {
    id: PERFORMANCE_STATISTIC.TOTAL_ONLINE_HOURS,
    title: 'TOTAL ONLINE HOURS',
    amount: 0,
    dataType: 'NUMBER',
    rate: 0,
    isAll: false
  }
];
