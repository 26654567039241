import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartModule } from 'primeng/chart';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Subject, takeUntil } from 'rxjs';
import { PerformanceStatisticOptions } from '@core/constants/dashboard';
import { DashboardService } from '@core/services/dashboard.service';
import { StatisticComponent } from '@feature/dashboard/components/statistic/statistic.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import { PERFORMANCE_STATISTIC } from '@core/enum/dashboard';
import { COLOR } from '@core/enum/color';

@Component({
  selector: 'app-performance-dashboard',
  standalone: true,
  imports: [SelectButtonModule, FormsModule, ChartModule, TranslateModule, StatisticComponent],
  templateUrl: './performance-dashboard.component.html',
  styleUrl: './performance-dashboard.component.scss'
})
export class PerformanceDashboardComponent implements OnChanges, OnDestroy {
  @Input() rangeDates: Date[] = [];
  @Input() userId?: string = '';

  readonly translatePrefix = 'dashboard.';
  readonly unsubscribe$ = new Subject();
  readonly periodOptions = [
    {
      title: 'Week',
      value: 'WEEK'
    },
    {
      title: 'Month',
      value: 'MONTH'
    }
  ];
  readonly barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          stepSize: 20
        }
      }
    }
  };

  performanceStatistics = PerformanceStatisticOptions;

  notesPeriodActive: string = 'WEEK';
  tasksPeriodActive: string = 'WEEK';
  appointmentsPeriodActive: string = 'WEEK';
  leadsPeriodActive: string = 'WEEK';

  totalNotes: number = 0;
  totalLeads: number = 0;
  totalTasks: number = 0;
  totalAppointments: number = 0;

  notesChartData: ChartData;
  tasksChartData: ChartData;
  appointmentsChartData: ChartData;
  leadsChartData: ChartData;

  constructor(private dashboardService: DashboardService) {}

  ngOnChanges(): void {
    this.fetchStatisticData();
    this.fetchLeadChartData();
    this.fetchNoteChartData();
    this.fetchAppointmentChartData();
    this.fetchTaskChartData();
  }

  get dateFrom() {
    return this.rangeDates?.[0] ? moment(this.rangeDates?.[0]).startOf('date').toDate().toISOString() : undefined;
  }

  get dateTo() {
    return this.rangeDates?.[1] ? moment(this.rangeDates?.[1]).endOf('date').toDate().toISOString() : undefined;
  }

  fetchStatisticData() {
    this.dashboardService
      .getPerformanceStatisticalData({
        userId: this.userId,
        from: this.dateFrom,
        to: this.dateTo
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const data = res.data;

          for (let option of this.performanceStatistics) {
            switch (option.id) {
              case PERFORMANCE_STATISTIC.TOTAL_LEADS: {
                option.amount = data.lead?.amount || 0;
                option.rate = data.lead?.rate || 0;
                break;
              }
              case PERFORMANCE_STATISTIC.TOTAL_NOTES: {
                option.amount = data.note?.amount || 0;
                option.rate = data.note?.rate || 0;
                break;
              }
              case PERFORMANCE_STATISTIC.TOTAL_TASKS: {
                option.amount = data.task?.amount || 0;
                option.rate = data.task?.rate || 0;
                break;
              }
              case PERFORMANCE_STATISTIC.TOTAL_APPOINTMENTS: {
                option.amount = data.task?.amount || 0;
                option.rate = data.task?.rate || 0;
                break;
              }
              case PERFORMANCE_STATISTIC.TOTAL_CLICK_CALLS: {
                option.amount = data.call?.amount || 0;
                option.rate = data.call?.rate || 0;
                break;
              }
              case PERFORMANCE_STATISTIC.TOTAL_ONLINE_HOURS: {
                break;
              }
            }
          }
        }
      });
  }

  fetchLeadChartData() {
    this.dashboardService
      .getPerformanceLeadsChartData(
        {
          userId: this.userId,
          from: this.dateFrom,
          to: this.dateTo
        },
        this.leadsPeriodActive
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const labels = res.data.map(item => item.periodName);
          const data = res.data.map(item => item.total);

          this.totalLeads = res.data.reduce((a, b) => a + b.total, 0);
          this.leadsChartData = {
            labels: labels,
            datasets: [
              {
                data,
                backgroundColor: COLOR.BLUE,
                maxBarThickness: 28,
                borderRadius: 2
              }
            ]
          };
        }
      });
  }

  fetchNoteChartData() {
    this.dashboardService
      .getPerformanceNotesChartData(
        {
          userId: this.userId,
          from: this.dateFrom,
          to: this.dateTo
        },
        this.notesPeriodActive
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const labels = res.data.map(item => item.periodName);
          const data = res.data.map(item => item.total);

          this.totalNotes = res.data.reduce((a, b) => a + b.total, 0);
          this.notesChartData = {
            labels: labels,
            datasets: [
              {
                data,
                backgroundColor: COLOR.BLUE,
                maxBarThickness: 28,
                borderRadius: 2
              }
            ]
          };
        }
      });
  }

  fetchTaskChartData() {
    this.dashboardService
      .getPerformanceTasksChartData(
        {
          userId: this.userId,
          from: this.dateFrom,
          to: this.dateTo
        },
        this.tasksPeriodActive
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const labels = res.data.map(item => item.periodName);
          const data = res.data.map(item => item.total);

          this.totalTasks = res.data.reduce((a, b) => a + b.total, 0);
          this.tasksChartData = {
            labels: labels,
            datasets: [
              {
                data,
                backgroundColor: COLOR.CYAN,
                maxBarThickness: 28,
                borderRadius: 2
              }
            ]
          };
        }
      });
  }

  fetchAppointmentChartData() {
    this.dashboardService
      .getPerformanceAppointmentChartData(
        {
          userId: this.userId,
          from: this.dateFrom,
          to: this.dateTo
        },
        this.appointmentsPeriodActive
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const labels = res.data.map(item => item.periodName);
          const data = res.data.map(item => item.total);

          this.totalAppointments = res.data.reduce((a, b) => a + b.total, 0);
          this.appointmentsChartData = {
            labels: labels,
            datasets: [
              {
                data,
                backgroundColor: COLOR.PURPLE,
                maxBarThickness: 28,
                borderRadius: 2
              }
            ]
          };
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
