<div class="h-content bg-white">
  <h1 class="px-8 pt-5 text-5 font-semibold">{{ translatePrefix + 'data-configuration' | translate }}</h1>

  <!-- TABS -->
  <div class="px-8 border-b">
    <app-tabs [tabs]="tabs" [(tabActive)]="tabActive" (onTabChange)="onTabChange()"></app-tabs>
  </div>

  <div class="px-8">
    <!-- CAPTION -->
    <div class="flex items-center justify-between pt-5 pb-4">
      <app-search-input inputClass="h-8" [debounceTime]="300" (onSearch)="onSearchField($event)" />

      @if (tabActive.value === FieldModuleEnum.LEAD) {
        <app-button
          [label]="'action.create' | translate"
          icon="icon-wl-plus-circle"
          size="sm"
          iconPosition="right"
          (onClick)="onCreateField()"></app-button>
      }
    </div>

    <!-- TABLE -->
    <p-table
      (onSort)="onSort($event)"
      [value]="dataConfig"
      [loading]="isLoading"
      [styleClass]="!isDataTable ? 'min-h-[200px]' : ''"
      loadingIcon="pi pi-spin pi-spinner text-blue-600">
      <ng-template pTemplate="header">
        <tr class="truncate">
          <th>{{ translatePrefix + 'field-name' | translate }}</th>
          <th>{{ translatePrefix + 'type' | translate }}</th>
          <th class="!text-center">{{ translatePrefix + 'options' | translate }}</th>
          <th class="!text-center">{{ translatePrefix + 'required' | translate }}</th>
          <th>{{ 'common.created-by' | translate }}</th>
          <th pSortableColumn="createdAt">
            <app-sort-table-header
              [label]="'common.created-date' | translate"
              [field]="'createdAt'"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th class="w-22"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr class="truncate">
          <td>{{ data.name }}</td>
          <td>
            <div class="flex items-center gap-2">
              <i class="{{ getField(data.fieldType)?.icon }} text-6"></i>
              <div>{{ getField(data.fieldType)?.label }}</div>
            </div>
          </td>
          <td class="!text-center">{{ data.optionSize }}</td>
          <td class="!text-center">
            <p-checkbox [binary]="true" [ngModel]="data.required" [disabled]="true"></p-checkbox>
          </td>
          <td>
            <div class="flex items-center gap-2">
              <app-custom-avatar
                [imgId]="data.createdBy.avatar"
                [name]="data.createdBy.fullName"
                size="sm"></app-custom-avatar>
              <span>{{ data.createdBy.fullName }}</span>
            </div>
          </td>
          <td>{{ data.createdAt | date: 'MM/dd/yyyy' }}</td>
          <td class="w-22 !px-2">
            <!-- EDIT FIELD -->
            <app-button
              styleClass="w-9"
              variant="ghost-primary"
              icon="icon-wl-edit text-6"
              (onClick)="onEditField(data.id)"></app-button>

            <!-- DELETE FIELD -->
            @if (data.dynamic) {
              <app-button
                styleClass="w-9"
                variant="ghost-secondary"
                icon="icon-wl-delete text-6"
                (onClick)="onDeleteField(data.id, data.name)"></app-button>
            }
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="7">
            <app-empty-table />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-field-edit
  [(visible)]="isEditField"
  [mode]="mode"
  [selectedId]="selectedId"
  [module]="tabActive.value"
  (refreshList)="fetchDataConfig()"></app-field-edit>
