<div class="bg-white">
  <div *ngIf="hasPermissions" class="px-8 pt-5 flex justify-between items-center">
    <h1 class="text-6 font-semibold">{{ translatePrefix + 'dashboard' | translate }}</h1>

    <div class="flex items-center gap-1.5">
      <!-- OWNER -->
      @if (tabActive !== DASHBOARD_TAB.USER) {
        <app-user-select-dropdown
          [isBorder]="true"
          [placeholder]="'common.all' | translate"
          [label]="'common.user' | translate"
          [users]="users"
          [totalUsers]="totalUsers"
          [(userSelected)]="userSelected"></app-user-select-dropdown>
      }

      <!-- USER GROUP -->
      @if (tabActive === DASHBOARD_TAB.POLICY) {
        <p-treeSelect [options]="userGroups" [(ngModel)]="userGroupSelected">
          <ng-template let-value pTemplate="value">
            <div class="flex gap-1 items-center max-w-[320px] truncate text-neutral-1000">
              <span class="!font-semibold">{{ translatePrefix + 'user-group' | translate }}: </span>
              <span class="max-w-[200px] truncate">{{ value?.label || ('common.all' | translate) }}</span>
            </div>
          </ng-template>

          <ng-template pTemplate="triggericon">
            <i class="icon-wl-arrow-drop-down text-6 text-neutral-1000"></i>
          </ng-template>
        </p-treeSelect>
      }

      <app-custom-calendar [(datesSelected)]="datesSelected" appendTo="body"></app-custom-calendar>

      @if (isValidReset) {
        <app-button label="Reset" variant="ghost-secondary" (onClick)="onResetFilter()" size="sm"></app-button>
      }
    </div>
  </div>

  <div *ngIf="hasPermissions; else noPermissions" class="flex bg-white px-8 border-b">
    @for (tab of tabs; track tab.title) {
      <div
        *ngxPermissionsOnly="tab.permission"
        class="flex h-13 px-5 items-center justify-center gap-2 border-b-2 cursor-pointer transition-all ease-in-out duration-300"
        [ngClass]="tabActive === tab.value ? 'border-primary-600 text-primary-600 border-b' : 'border-transparent'"
        (click)="onClickTab(tab.value)">
        <span class="font-medium">{{ translatePrefix + tab.title | translate }}</span>
      </div>
    }
  </div>

  <!-- DASHBOARD -->
  <div *ngIf="hasPermissions" class="px-8 py-6">
    @switch (tabActive) {
      @case (DASHBOARD_TAB.LEAD) {
        <app-lead-dashboard [userId]="userSelected?.id" [rangeDates]="datesSelected"></app-lead-dashboard>
      }
      @case (DASHBOARD_TAB.POLICY) {
        <app-policy-dashboard
          [userId]="userSelected?.id"
          [userGroupId]="userGroupSelected?.key"
          [rangeDates]="datesSelected"></app-policy-dashboard>
      }
      @case (DASHBOARD_TAB.PERFORMANCE) {
        <app-performance-dashboard [userId]="userSelected?.id" [rangeDates]="datesSelected"></app-performance-dashboard>
      }
      @case (DASHBOARD_TAB.USER) {
        <app-user-dashboard [rangeDates]="datesSelected"></app-user-dashboard>
      }
    }
  </div>
</div>

<!-- NO PERMISSIONS MESSAGE -->
<ng-template #noPermissions>
  <div class="p-8 text-red-500 w-full h-[94vh] bg-white flex items-center justify-center">
    <h2>You don't have any permission to view this dashboard.</h2>
  </div>
</ng-template>
