import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NodeLevel, ViewOrganization } from '@core/enum/company-organization';
import { OrganizationCard, UserState } from '@core/models/interfaces/company-organization';
import { LeadDetails } from '@core/models/interfaces/lead';
import { UserDetail } from '@core/models/interfaces/user';
import { TranslateModule } from '@ngx-translate/core';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { TreeNode } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { OrgChartDropdownOptionComponent } from '../org-chart-dropdown-option/org-chart-dropdown-option.component';
import { StatusUser } from '@core/enum/user';

@Component({
  selector: 'app-org-chart-item',
  standalone: true,
  imports: [
    CommonModule,
    CustomAvatarComponent,
    OrgChartDropdownOptionComponent,
    CheckboxModule,
    FormsModule,
    TranslateModule
  ],
  templateUrl: './org-chart-item.component.html',
  styleUrl: './org-chart-item.component.scss'
})
export class OrgChartItemComponent {
  @Input() nodeItemData: OrganizationCard;
  @Input() viewChart: ViewOrganization = ViewOrganization.VIEW;
  @Input() userList: UserDetail[] = [];
  @Input() totalUser: number = 0;
  @Input() typeNode: string;
  @Input() chartList: TreeNode<any>[];

  @Output() viewChartChange = new EventEmitter();
  @Output() actionNodeItem = new EventEmitter();
  @Output() touchedNodeItem = new EventEmitter();
  @Output() updateNewNodeItem = new EventEmitter();

  readonly ViewOrganization = ViewOrganization;
  readonly NodeLevel = NodeLevel;
  readonly translatePrefix = 'company-organization.';
  readonly StatusUser = StatusUser;

  isVisible: boolean = false;
  isFirstClickIgnored: boolean = false;

  userState: UserState = {
    data: [],
    loading: false,
    total: 0,
    keyword: '',
    page: 0,
    size: 10
  };

  idsUserSelected: string[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.userList?.length) {
      this.userState.data = this.userList;
      this.userState.total = this.totalUser;
    }
  }

  onClickItem(card: OrganizationCard) {
    this.touchedNodeItem.emit(card);
    if (this.viewChart === ViewOrganization.EDIT) {
      this.viewChart = ViewOrganization.EDIT;
      this.viewChartChange.emit(this.viewChart);

      if (!this.isFirstClickIgnored) {
        this.isFirstClickIgnored = true;
        return;
      }
      this.idsUserSelected = this.getAllUniqueUserIds(this.chartList);

      this.isVisible = true;
    } else {
      card?.status?.code !== StatusUser.HIDDEN ? this.touchedNodeItem.emit(): null;
    }
  }

  getAllUniqueUserIds(nodes: TreeNode<OrganizationCard>[]): string[] {
    let userIds: string[] = [];

    function recurse(items: TreeNode[]) {
      items.forEach(item => {
        if (item.data && item.data.id) {
          userIds.push(item.data.id);
        }

        if (item.children && item.children.length > 0) {
          recurse(item.children);
        }
      });
    }

    recurse(nodes);
    return userIds;
  }

  onAddNode(node: NodeLevel, card: OrganizationCard) {
    this.actionNodeItem.emit({ node: node, data: card });
  }

  onCloseNodeItem(card: OrganizationCard) {
    this.actionNodeItem.emit({ node: NodeLevel.NONE, data: card });
  }

  onSelectedOption(value: OrganizationCard) {
    this.updateNewNodeItem.emit({
      data: { ...value, isHiring: this.nodeItemData.isHiring, parentId: this.nodeItemData.parentId, isInvalid: false },
      currentData: this.nodeItemData
    });
    this.nodeItemData = { ...this.nodeItemData, ...value };
  }

  onHiringEvent(event: Event) {
    event.stopPropagation();
  }
}
