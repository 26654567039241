<div>
  <app-custom-dialog
    [draggable]="false"
    size="md"
    [style]="{ 'min-width': '640px' }"
    [(visible)]="visible"
    (visibleChange)="onCancel()"
    [header]="translatePrefix + 'add-link' | translate">
    <form content class="flex flex-col gap-6 relative">
      <div class="flex flex-col gap-2">
        <span>{{ translatePrefix + 'content' | translate }}</span>

        <div class="flex flex-col gap-3">
          <!-- Title -->
          <app-form-control
            [label]="translatePrefix + 'title' | translate"
            [control]="bannerTitleControl"
            [floatLabel]="true"
            [required]="true"
            [validates]="[
              {
                error: 'required',
                message: translatePrefix + 'this-field-is-required' | translate
              }
            ]">
            <input input pInputText class="w-full" [formControl]="bannerTitleControl" />
          </app-form-control>

          <!-- URL -->
          <div class="flex flex-col gap-1">
            <div class="flex items-center border border-neutral-300 rounded-sm h-12">
              <div class="flex-1 flex items-center gap-2 justify-between px-4 py-3.5">
                @if (this.imageSelect.imageName) {
                  @if (this.imageSelect.loading) {
                    <i class="pi pi-spin pi-spinner" style="font-size: 10px"></i>
                  } @else {
                    <span>{{ this.imageSelect.imageName }}</span>
                  }

                  <span>{{ this.imageSelect.imageSize ?? 0 | fileSize }}</span>
                } @else {
                  <span class="text-neutral-600">{{ translatePrefix + 'select-image' | translate }}</span>
                }
              </div>
              <div
                class="flex items-center gap-1.5 text-blue-600 bg-neutral-200 hover:bg-neutral-300 w-fit h-full border-l border-neutral-300 px-4 py-3.5 cursor-pointer"
                (click)="uploadFile.click()">
                <i class="icon-wl-upload-cloud text-6"></i>
                <span class="font-medium">{{ translatePrefix + 'browser' | translate }}</span>
              </div>
            </div>

            <div class="min-h-5">
              @if (imageSelect.imageWidth || imageSelect.imageHeight) {
                <span class="px-4 text-2">{{
                  translatePrefix + 'recommended-size'
                    | translate: { width: imageSelect.imageWidth, height: imageSelect.imageHeight }
                }}</span>
              }
            </div>
          </div>
        </div>
        @if (imageSelect.imageUrl) {
          @if (!this.imageSelect.loading) {
            <div class="flex flex-col gap-2 p-3 rounded-sm border border-neutral-300">
              <span class="font-medium">{{ translatePrefix + 'review' | translate }}</span>

              <!-- Image -->
              <div class="w-full h-full">
                <img [src]="imageSelect.imageUrl" alt="img" class="object-cover rounded" />
              </div>
            </div>
          } @else {
            <div class="h-[200px] bg-neutral-1000/10 flex justify-center items-center rounded-sm">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
          }
        }
      </div>
      @if (loading) {
        <div class="absolute w-full h-full flex justify-around items-center bg-neutral-1000/10">
          <div class="flex justify-center items-center">
            <app-custom-loading></app-custom-loading>
          </div>
        </div>
      }
    </form>

    <div class="pt-4" footer>
      <app-button
        [label]="'action.cancel' | translate"
        variant="outline-secondary"
        size="xl"
        (onClick)="onCancel()"
        [disabled]="loading"></app-button>
      <app-button
        [disabled]="loading"
        [label]="'action.save' | translate"
        size="xl"
        (onClick)="onSaveBanner()"></app-button>
    </div>
  </app-custom-dialog>
</div>
<input
  class="hidden"
  #uploadFile
  type="file"
  accept=" .jpeg, .jpg, .png"
  (change)="onChangeFile($event)"
  (click)="uploadFile.value = ''" />
