import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LANGUAGES } from '@core/constants/coutries';
import { LocalStorageEnum } from '@core/enum/local-storage';
import { LanguageItem } from '@core/models/interfaces/system';
import { AccountSettingService } from '@core/services/account-setting.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@state/app.state';
import { updateUserInfoField } from '@state/auth/auth.actions';
import { DropdownModule } from 'primeng/dropdown';
import { Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-flags',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule],
  templateUrl: './flags.component.html',
  styleUrl: './flags.component.scss'
})
export class FlagsComponent {
  readonly languages = LANGUAGES;
  readonly unsubscribe$ = new Subject();
  selectedLanguage: LanguageItem;

  constructor(
    private translateService: TranslateService,
    private accountSettingService: AccountSettingService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    const language = localStorage.getItem(LocalStorageEnum.LANGUAGE) || this.translateService.getDefaultLang();
    this.selectedLanguage = this.languages.find(c => c.language === language) || this.languages[0];
  }

  changeLocale(item: LanguageItem) {
    const language = (item.language as string) || localStorage.getItem('lang') || 'en';

    this.accountSettingService
      .updateLanguageUser(language)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.translateService.use(language);
          this.store.dispatch(updateUserInfoField({ field: 'language', value: res?.data?.language ?? '' }));
          this.updateLanguageUserStorage('language', res?.data?.language ?? 'en');
          localStorage.setItem(LocalStorageEnum.LANGUAGE, language);
        }
      });
  }

  updateLanguageUserStorage(fieldName: string, value: string | boolean) {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      user[fieldName] = value;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
