<!-- Statistics -->
<div class="grid grid-cols-2 md:grid-cols-3 gap-6">
  @for (statistic of leadStatistics; track $index) {
    <div class="flex-1">
      <app-statistic
        [title]="statistic.title"
        [amount]="statistic.amount"
        [dataType]="statistic.dataType"
        [rate]="statistic.rate"
        [isAll]="statistic.isAll"></app-statistic>
    </div>
  }
</div>

<div class="grid grid-cols-3 gap-6 mt-6">
  <!-- Leads by status -->
  <div class="flex flex-col justify-between rounded-md border px-6 py-5">
    <div>
      <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'leads-by-status' | translate }}</h2>
      <div class="pt-7 pb-13 flex justify-center">
        <p-chart
          width="200px"
          height="200px"
          type="doughnut"
          [data]="leadsByStatusChartData"
          [options]="doughnutChartOptions"></p-chart>
      </div>
    </div>

    <app-doughnut-chart-legend [options]="leadsByStatus"></app-doughnut-chart-legend>
  </div>

  <!-- Leads by source -->
  <div class="flex flex-col justify-between rounded-md border px-6 py-5">
    <div>
      <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'leads-by-source' | translate }}</h2>
      <div class="pt-7 pb-13 flex justify-center">
        <p-chart
          width="200px"
          height="200px"
          type="doughnut"
          [data]="leadsBySourceChartData"
          [options]="doughnutChartOptions"></p-chart>
      </div>
    </div>

    <app-doughnut-chart-legend [options]="leadsBySource"></app-doughnut-chart-legend>
  </div>

  <!-- Leads by stage -->
  <div class="flex flex-col justify-between rounded-md border px-6 py-5">
    <div>
      <div class="text-lg font-bold pb-5">{{ translatePrefix + 'leads-by-stage' | translate }}</div>

      <!-- Funnel Chart -->
      <div class="flex gap-4 pt-7 pb-8">
        <div class="flex-1 text-neutral-600 flex flex-col gap-0.5">
          @for (stage of leadsByStage; track $index) {
            <div class="flex items-center" [ngClass]="leadsByStageChartData.length > 4 ? 'h-9' : 'h-12'">
              <span class="max-w-25 truncate">{{ stage.label }}</span>
            </div>
          }
        </div>

        <div class="w-[200px] flex flex-col gap-0.5 text-2 text-white">
          @for (item of leadsByStageChartData; track $index) {
            <div
              class="bg-blue-300 flex items-center justify-center"
              [ngClass]="leadsByStageChartData.length > 4 ? 'h-9' : 'h-12'"
              style="clip-path: polygon(0% 0%, 100% 0%, 92% 100%, 8% 100%)"
              [ngStyle]="{ 'clip-path': item.polygon, 'background-color': item.bgColor }">
              {{ item.data }}
            </div>
          }
        </div>

        <div class="text-neutral-600 flex flex-col gap-0.5">
          @for (item of leadsByStageChartData; track $index) {
            <div class="flex items-center" [ngClass]="leadsByStageChartData.length > 4 ? 'h-9' : 'h-12'">
              {{ item.percent | number: '1.2-2' }}%
            </div>
          }
        </div>
      </div>
    </div>

    <app-doughnut-chart-legend [options]="leadsByStage"></app-doughnut-chart-legend>
  </div>

  <!-- Lead type -->
  <div class="flex flex-col justify-between rounded-md border px-6 py-5">
    <div>
      <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'lead-type' | translate }}</h2>
      <div class="pt-7 pb-13 flex justify-center">
        <p-chart
          width="200px"
          height="200px"
          type="doughnut"
          [data]="leadTypeChartData"
          [options]="doughnutChartOptions"></p-chart>
      </div>
    </div>

    <app-doughnut-chart-legend [options]="leadType"></app-doughnut-chart-legend>
  </div>

  <!-- Leads by Owner -->
  <div class="flex flex-col rounded-md border px-6 pt-5 pb-10">
    <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'leads-by-owner' | translate }}</h2>
    <div class="pt-2 pb-4">
      <p-chart
        height="256px"
        width="100%"
        type="line"
        [data]="leadsByOwnerChartData"
        [options]="lineChartOptions"></p-chart>
    </div>
  </div>

  <!-- Lead tag -->
  <div class="flex flex-col rounded-md border px-6 pt-5 pb-10">
    <div class="flex items-center justify-between">
      <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'lead-tag' | translate }}</h2>

      <!-- <p-multiSelect
        styleClass="border-transparent hover:border-blue-600 w-32 -mr-4"
        [options]="['Old', 'Young']"
        dropdownIcon="icon-wl-arrow-drop-down">
        <ng-template pTemplate="selectedItems" let-implicit> {{ implicit?.length }} selected </ng-template>
      </p-multiSelect> -->
    </div>
    <div class="pt-2 pb-4">
      <p-chart height="256px" width="100%" type="bar" [data]="leadTagChartData" [options]="barChartOptions"></p-chart>
    </div>
  </div>

  <!-- Leads by states -->
  <div class="flex flex-col rounded-md border px-6 pt-5 pb-10">
    <div class="flex items-center justify-between">
      <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'leads-by-states' | translate }}</h2>
      <!-- <p-multiSelect
        styleClass="border-transparent hover:border-blue-600 w-32 -mr-4"
        [options]="['Old', 'Young']"
        dropdownIcon="icon-wl-arrow-drop-down">
        <ng-template pTemplate="selectedItems" let-implicit> {{ implicit?.length }} selected </ng-template>
      </p-multiSelect> -->
    </div>
    <div class="flex-1 pt-2 pb-4">
      <p-chart
        height="256px"
        width="100%"
        type="bar"
        [data]="leadsByStatesChartData"
        [options]="barChartOptions"></p-chart>
    </div>
  </div>

  <!-- Leads by age & gender -->
  <div class="flex flex-col justify-between rounded-md border px-6 py-5">
    <div>
      <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'leads-by-age-gender' | translate }}</h2>
      <div class="flex-1 pt-2 pb-4">
        <p-chart
          height="256px"
          width="100%"
          type="bar"
          [data]="leadsByAgeAndGenderChartData"
          [options]="barChartOptions"></p-chart>
      </div>
    </div>

    <app-doughnut-chart-legend [options]="leadsByGender"></app-doughnut-chart-legend>
  </div>

  <!-- Leads by gender -->
  <div class="flex flex-col justify-between rounded-md border px-6 py-5">
    <div>
      <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'leads-by-gender' | translate }}</h2>
      <div class="pt-7 pb-13 flex justify-center">
        <p-chart
          width="200px"
          height="200px"
          type="doughnut"
          [data]="leadsByGenderChartData"
          [options]="doughnutChartOptions"></p-chart>
      </div>
    </div>

    <app-doughnut-chart-legend [options]="leadsByGender"></app-doughnut-chart-legend>
  </div>
</div>
