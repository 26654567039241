@if (productDetailsReport.length > 0) {
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <h3 class="font-semibold text-4">
        {{ translatePrefix + 'report' | translate }} {{ productDetailsReportDate | date: 'MM/dd/yyyy HH:mm' }}
      </h3>

      <div>
        <div class="flex gap-1.5">
          <app-button
            label="Filter"
            size="sm"
            icon="icon-wl-filters-line"
            iconPosition="left"
            variant="outline-secondary"
            [styleClass]="isFilter ? '!bg-neutral-200' : ''"
            (onClick)="isFilter = !isFilter"></app-button>
          <app-button
            *ngxPermissionsOnly="Permission.REPORT_EXPORT"
            (onClick)="exportMenu.toggle($event)"
            label="Export"
            size="sm"
            icon="icon-wl-upload"
            iconPosition="left"
            [isDropdown]="true"
            variant="outline-secondary"></app-button>
        </div>
        <p-menu #exportMenu [model]="exportOptions" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <div class="flex items-center gap-2 p-2 cursor-pointer">
              <img class="w-5 h-5" [src]="item.img" [alt]="item.title" />
              <span>{{ item.title }}</span>
            </div>
          </ng-template>
        </p-menu>
      </div>
    </div>

    @if (isFilter) {
      <p-dropdown
        styleClass="h-8 rounded-full"
        placeholder="Group by"
        [options]="ProductDetailsGroupBys"
        [(ngModel)]="groupBy"
        (onChange)="fetchProductDetailsReport()"
        optionLabel="title"
        optionValue="value"
        dropdownIcon="icon-wl-arrow-drop-down">
        <ng-template pTemplate="selectedItem" let-implicit>
          <div class="flex gap-1 items-center max-w-[320px] truncate text-neutral-1000">
            <span class="!font-semibold">{{ 'Group by' | translate }}: </span>
            <span class="max-w-[200px] truncate">{{ implicit.title || ('common.all' | translate) }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    }

    <div class="relative">
      @if (isLoading) {
        <app-custom-loading
          styleClass="absolute top-0 bottom-0 left-0 right-0 z-[999] bg-neutral-alpha-200A  rounded-md"></app-custom-loading>
      }

      <div class="flex flex-col gap-4">
        @for (report of productDetailsReport; track $index) {
          <app-product-item-report
            [index]="$index + 1 + pagination.page * pagination.size"
            [report]="report"
            [params]="params"
            [groupBy]="groupBy"></app-product-item-report>
        }
      </div>
    </div>

    <app-custom-paginator
      [(pagination)]="pagination"
      [totalRecords]="totalReports"
      (onChange)="onPagination()"></app-custom-paginator>
  </div>
} @else {
  @if (isFetched) {
    <app-empty title="No data" size="xs"></app-empty>
  }
}
