<ng-template
  [ngxPermissionsOnly]="Permission.REPORT_PRODUCTION_DETAILS_RUN"
  (permissionsAuthorized)="checkPermission(Permission.REPORT_PRODUCTION_DETAILS_RUN, true)"
  (permissionsUnauthorized)="checkPermission(Permission.REPORT_PRODUCTION_DETAILS_RUN, false)"></ng-template>

<ng-template
  [ngxPermissionsOnly]="Permission.REPORT_PERFORMANCE_RUN"
  (permissionsAuthorized)="checkPermission(Permission.REPORT_PERFORMANCE_RUN, true)"
  (permissionsUnauthorized)="checkPermission(Permission.REPORT_PERFORMANCE_RUN, false)"></ng-template>

<ng-template
  [ngxPermissionsOnly]="Permission.REPORT_LEAD_RUN"
  (permissionsAuthorized)="checkPermission(Permission.REPORT_LEAD_RUN, true)"
  (permissionsUnauthorized)="checkPermission(Permission.REPORT_LEAD_RUN, false)"></ng-template>

<div class="py-5 px-8 min-h-content bg-white">
  <h1 class="font-semibold text-6">{{ translatePrefix + 'report' | translate }}</h1>

  <p-selectButton
    styleClass="my-4"
    [options]="tabOptions"
    [allowEmpty]="false"
    [(ngModel)]="tabActive"
    optionValue="value">
    <ng-template pTemplate="item" let-item>
      <div>
        {{ translatePrefix + item.title | translate }}
      </div>
    </ng-template>
  </p-selectButton>

  <div class="flex flex-col gap-6">
    @switch (tabActive) {
      <!-- PRODUCT -->
      @case (ReportTabEnum.PRODUCT) {
        <app-production-details-selection
          [(isRunning)]="isRunningProductDetailsReport"
          [(params)]="productDetailsReportParams"
          [commissionTeams]="commissionTeams"
          [companies]="companies"
          [productTypes]="productTypes"
          [products]="products"
          [sources]="sources"
          [policyStatuses]="policyStatuses"
          [users]="users"></app-production-details-selection>

        <app-product-details-report
          [(isRunning)]="isRunningProductDetailsReport"
          [params]="productDetailsReportParams"></app-product-details-report>
      }

      <!-- PERFORMANCE -->
      @case (ReportTabEnum.PERFORMANCE) {
        <app-performance-selection
          [(params)]="performanceReportParams"
          [users]="users"
          [(isRunning)]="isRunningPerformanceReport"
          [userGroups]="userGroups"></app-performance-selection>

        <app-performance-report
          [params]="performanceReportParams"
          [(isRunning)]="isRunningPerformanceReport"></app-performance-report>
      }

      <!-- LEAD -->
      @case (ReportTabEnum.LEAD) {
        <app-performance-selection
          [(params)]="leadReportParams"
          [users]="users"
          [(isRunning)]="isRunningLeadReport"
          [userGroups]="userGroups"></app-performance-selection>

        <app-lead-report [params]="leadReportParams" [(isRunning)]="isRunningLeadReport"></app-lead-report>
      }
    }
  </div>
</div>
