export enum PerformanceSortFieldEnum {
  NOTE = 'notes',
  CLICK_CALL = 'clickCalls',
  UPDATED_LEAD = 'updatedLeads',
  NO_UPDATED_LEAD = 'noUpdatedLeads',
  TASK = 'tasks',
  APPOINTMENT = 'appointments',
  LEAD_CREATED = 'createdLeads',
  POLICY_CREATED = 'createdPolicies',
  HOURS_ONLINE = 'hoursOnline'
}

export enum LeadSortFieldEnum {
  LEAD_MOVED = 'leadMoved',
  LEAD_TAKE_CARE = 'leadTakeCare',
  LEAD_CREATED = 'leadCreated',
  CUSTOMER = 'customer',
  PROSPECT = 'prospect',
  HIDDEN = 'hidden'
}

export enum ReportTabEnum {
  PRODUCT = 'PRODUCT',
  PERFORMANCE = 'PERFORMANCE',
  LEAD = 'LEAD'
}
