import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BANNER_LOCATION } from '@core/enum/admin-content';
import { BannerDetail } from '@core/models/interfaces/admin-content/admin-content';
import { BannerService } from '@core/services/amin-content/banner.service';
import { CustomImageComponent } from '@shared/components/custom-image/custom-image.component';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [RouterOutlet, CustomImageComponent],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss'
})
export class AuthLayoutComponent implements OnInit {
  readonly environment = environment;
  readonly unsubscribe$ = new Subject();

  bannerState: { data?: BannerDetail; loading: boolean } = {
    data: undefined,
    loading: false
  };

  constructor(private bannerService: BannerService) {}

  ngOnInit(): void {
    this.fetchBanners();
  }

  fetchBanners() {
    this.bannerState.loading = true;
    this.bannerService
      .getBannerManagement()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.bannerState = {
            data: res?.data?.content.find(
              el => el.location.replace(/\s+/g, '').toUpperCase() === BANNER_LOCATION.SIGN_IN
            ),
            loading: false
          };
        },
        error: () => {
          this.bannerState.loading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next('Complete');
    this.unsubscribe$.complete();
  }
}
