<div class="flex flex-col gap-4 min-h-full mx-auto px-8 py-5 bg-white">
  <h2 class="text-5 font-semibold">{{ translatePrefix + 'note-management' | translate }}</h2>

  <!-- CAPTION -->
  <div class="flex items-center justify-between">
    <div class="w-1/3 pr-[calc(40px_/_3)]">
      <app-search-input
        [placeholder]="'action.search' | translate"
        inputClass="w-full h-8"
        [debounceTime]="300"
        (onSearch)="onSearchNote($event)" />
    </div>
    <div class="flex gap-1.5">
      <app-button
        variant="outline-secondary"
        [label]="'action.sort' | translate"
        icon="icon-wl-swap-vertical"
        iconPosition="left"
        size="sm"
        [styleClass]="isSort ? '!bg-neutral-200' : ''"
        (onClick)="isSort = !isSort"></app-button>
      <app-button
        variant="outline-secondary"
        [label]="'action.filters' | translate"
        icon="icon-wl-filters-line"
        iconPosition="left"
        size="sm"
        [styleClass]="isFilter ? '!bg-neutral-200' : ''"
        (onClick)="isFilter = !isFilter"></app-button>
    </div>
  </div>

  @if (isSort || isFilter) {
    <div class="flex items-center flex-wrap gap-1.5">
      @if (isSort) {
        <!-- SORTS -->
        <p-dropdown
          [options]="dateAddedOptions"
          [(ngModel)]="dateAddedSort"
          optionLabel="title"
          styleClass="h-8 rounded-full"
          dropdownIcon="icon-wl-arrow-drop-down"
          (onChange)="fetchLeadNotes()">
          <ng-template let-value pTemplate="selectedItem">
            <div class="flex gap-1 items-center truncate">
              <span [ngClass]="{ 'font-semibold': dateAddedSort.title }">{{ 'common.date-added' | translate }}: </span>
              <span class="max-w-[200px] truncate">{{ dateAddedSort.title }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      }

      @if (isSort && isFilter) {
        <div class="h-5 w-[1px] bg-neutral-alpha-200A"></div>
      }

      @if (isFilter) {
        <!-- FILTERS -->
        <p-multiSelect
          [resetFilterOnHide]="true"
          name="status"
          optionLabel="name"
          placeholder="Status"
          [options]="noteStatuses"
          [showToggleAll]="false"
          [(ngModel)]="filters.statusFilter"
          (onChange)="onChangeFilters()"
          styleClass="!h-8 rounded-full"
          dropdownIcon="icon-wl-arrow-drop-down">
          <ng-template let-value pTemplate="selectedItems">
            <div class="flex gap-1 items-center truncate text-neutral-1000">
              <span class="!font-semibold">{{ translatePrefix + 'note-type' | translate }}: </span>
              <span class="max-w-[200px] truncate">{{ statusSelected || ('common.all' | translate) }}</span>
            </div>
          </ng-template>
          <ng-template let-status pTemplate="item">
            <div class="min-w-[116px]">
              <div
                class="rounded-sm text-2 font-medium py-[2px] px-2 w-fit"
                [style.color]="status.color"
                [style.backgroundColor]="status.backgroundColor">
                {{ status.name }}
              </div>
            </div>
          </ng-template>
        </p-multiSelect>

        <p-dropdown
          [options]="groupByOptions"
          [(ngModel)]="groupBy"
          optionLabel="title"
          optionValue="value"
          styleClass="h-8 rounded-full"
          dropdownIcon="icon-wl-arrow-drop-down"
          (onChange)="onChangeGroupBy()">
          <ng-template let-value pTemplate="selectedItem">
            <div class="flex gap-1 items-center truncate">
              <span class="!font-semibold">{{ translatePrefix + 'group-by' | translate }}: </span>
              <span class="max-w-[200px] truncate">{{ value.title }}</span>
            </div>
          </ng-template>
        </p-dropdown>

        <app-user-multi-select-dropdown
          [label]="'lead-details.overview-detail.added-by' | translate"
          [users]="users"
          [totalUsers]="totalUsers"
          [(usersSelected)]="filters.addedByFilter"
          (usersSelectedChange)="onChangeFilters()"></app-user-multi-select-dropdown>

        <app-custom-calendar
          [(datesSelected)]="filters.datesFilter"
          (datesSelectedChange)="onChangeFilters()"></app-custom-calendar>

        <app-button
          variant="ghost-primary"
          [label]="'action.reset' | translate"
          [disabled]="resetDisabled"
          (onClick)="onResetFilter()"></app-button>
      }
    </div>
  }

  <div class="relative min-h-[142px]">
    @if (isLoading) {
      <app-custom-loading
        styleClass="absolute inset-0 h-full flex items-center justify-center bg-neutral-600/10"></app-custom-loading>
    }

    @if (noteGroups.length || notes.length) {
      <div class="flex flex-col gap-6">
        @if (groupBy === 'LEAD') {
          @for (group of noteGroups; track group.id) {
            <app-note-table
              [noteStatuses]="noteStatuses"
              [leadId]="group.id"
              [dateAddedSort]="dateAddedSort"
              [filters]="filters"
              [group]="group"
              [userId]="this.userInfo.id"
              (onDeleteNoteLead)="deleteNoteLead()"></app-note-table>
          }
        } @else {
          <div class="flex flex-col gap-4">
            @for (note of notes; track note.id) {
              <app-note-management-policy
                [leadId]="note.leadInfoRepresentation.id"
                [noteStatuses]="noteStatuses"
                [note]="note"
                (onRefresh)="fetchLeadNotes()"></app-note-management-policy>
            }
          </div>
        }

        @if (groupBy === 'LEAD') {
          <app-custom-paginator
            [(pagination)]="paginationGroup"
            [totalRecords]="totalGroups"
            (onChange)="fetchNoteGroups()">
          </app-custom-paginator>
        } @else {
          <app-custom-paginator [(pagination)]="pagination" [totalRecords]="totalNotes" (onChange)="fetchLeadNotes()">
          </app-custom-paginator>
        }
      </div>
    } @else {
      <app-empty background="empty" [title]="translatePrefix + 'no-notes-title' | translate"></app-empty>
    }
  </div>
</div>
