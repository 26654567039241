import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { MenuItem, MessageService } from 'primeng/api';

import { LeadSortFieldEnum } from '@core/enum/report';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { SortTableHeaderComponent } from '@shared/components/sort-table-header/sort-table-header.component';
import { GetPerformanceParams, LeadReport, PerformanceParams } from '@core/models/interfaces/report';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { ReportService } from '@core/services/report.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { downloadFile } from '@shared/utils/dowloadFile.util';
import { APP_PERMISSION } from '@core/enum/permisson';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-lead-report',
  standalone: true,
  imports: [
    TableModule,
    TranslateModule,
    MenuModule,
    CommonModule,
    NgxPermissionsModule,
    SortTableHeaderComponent,
    CustomPaginatorComponent,
    EmptyTableComponent,
    ButtonComponent
  ],
  templateUrl: './lead-report.component.html',
  styleUrl: './lead-report.component.scss'
})
export class LeadReportComponent implements OnChanges {
  @Input() params: PerformanceParams;
  @Input() isRunning: boolean = false;

  @Output() isRunningChange = new EventEmitter<boolean>();

  readonly Permission = APP_PERMISSION;
  readonly LeadSortFieldEnum = LeadSortFieldEnum;
  readonly translatePrefix = 'report.';
  readonly messagePrefix = 'report.message.';
  readonly exportOptions: MenuItem[] = [
    {
      title: 'CSV',
      img: '/assets/images/icons/file-csv.svg',
      command: () => {
        this.onExportReport('CSV');
      }
    },
    {
      title: 'PDF',
      img: '/assets/images/icons/file-pdf.svg',
      command: () => {
        this.onExportReport('PDF');
      }
    }
  ];

  leadReportDate: Date;
  isFetched: boolean = false;
  isLoading: boolean = false;
  leadReport: LeadReport[] = [];
  totalReports: number = 0;
  pagination = {
    page: 0,
    size: 10
  };
  sortField: string = '';
  sortOrder: number = 1;

  constructor(
    private reportService: ReportService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['params']?.currentValue) {
      this.leadReportDate = new Date();
      this.pagination = {
        page: 0,
        size: 10
      };
      this.fetchLeadReport();
    }
  }

  get leadReportParams(): GetPerformanceParams {
    const groupIds = this.params.groups?.map(item => item.key)?.filter(item => !!item) as string[];
    return {
      ...this.params,
      groupIds
    };
  }

  fetchLeadReport() {
    const sorts = this.sortField ? (this.sortOrder < 0 ? this.sortField : `-${this.sortField}`) : '';
    const params = this.leadReportParams;

    this.isLoading = true;
    this.reportService
      .getLeadReport({
        ...params,
        page: this.pagination.page,
        size: this.pagination.size,
        sorts
      })
      .subscribe({
        next: res => {
          this.isLoading = false;
          this.isFetched = true;
          this.leadReport = res.data.content;
          this.totalReports = res.data.total;

          this.isRunning = false;
          this.isRunningChange.emit(this.isRunning);
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onSort(event: { field: string; order: number }) {
    this.sortField = event.field;
    this.sortOrder = event.order;

    this.fetchLeadReport();
  }

  onPagination() {
    this.fetchLeadReport();
  }

  onExportReport(type: 'CSV' | 'PDF') {
    this.reportService.exportPerformanceReport(type, { ...this.params }).subscribe({
      next: res => {
        downloadFile(res, 'Lead Report');
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(this.messagePrefix + 'export-lead-report-failed')
        });
      }
    });
  }
}
