<app-custom-dialog
  [draggable]="false"
  size="xl"
  [style]="{ 'min-width': '640px' }"
  [(visible)]="visible"
  (visibleChange)="onCancel()"
  [header]="translatePrefix + 'create-post' | translate">
  <div class="" content>
    <div class="flex flex-col gap-6 relative">
      <div class="flex flex-col gap-2">
        <span class="font-medium">{{ translatePrefix + 'content' | translate }}</span>

        <div class="flex flex-col gap-3">
          <!-- Title -->
          <p-floatLabel>
            <input id="title" type="text" pInputText [(ngModel)]="postCreate.title" />
            <label for="title">
              {{ translatePrefix + 'title' | translate }} <span class="text-red-500 ml-0.5">*</span></label
            >
          </p-floatLabel>

          <!-- Description -->

          <p-editor
            #editor
            [placeholder]="translatePrefix + 'description' | translate"
            [(ngModel)]="postCreate.description"
            [placeholder]="translatePrefix + 'description' | translate"
            [style]="{ height: '200px' }">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <!-- Custom header dropdown -->
                <select class="ql-header">
                  <option selected></option>
                  <option value="1">Heading 1</option>
                  <option value="2">Heading 2</option>
                  <option value="3">Heading 3</option>
                </select>
              </span>

              <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>

              <span class="ql-formats">
                <button type="button" class="ql-list" aria-label="Ordered List" value="ordered"></button>
                <button type="button" class="ql-list" aria-label="Unordered List" value="bullet"></button>
              </span>
              <span class="ql-formats">
                <button type="button" class="ql-link" aria-label="Insert Link"></button>
                <button type="button" class="ql-code-block" aria-label="Insert Code Block"></button>
              </span>
            </ng-template>
          </p-editor>
        </div>
      </div>

      <!-- ATTACHMENTS -->
      <div class="flex flex-col gap-2">
        <span class="font-semibold">{{ translatePrefix + 'attachment' | translate }}</span>

        <!-- Upload -->
        <div class="flex flex-col gap-3">
          <div class="relative">
            <app-upload-file
              styleClass="!min-w-full"
              [multiple]="true"
              (onUploadFile)="onUploadFile($event)"
              [description]="translatePrefix + 'description-type-file' | translate"
              accepts=" .jpeg, .jpg, .png"
              styleClass="focus:border-blue-500"></app-upload-file>

            @if (isLoading) {
              <div class="h-32 w-full absolute top-0 bottom-0 bg-neutral-1000/20">
                <app-custom-loading></app-custom-loading>
              </div>
            }
          </div>

          <div class="flex gap-2 items-center">
            @if (postCreate?.attachments) {
              @for (item of postCreate?.attachments; track $index) {
                <div class="relative">
                  <app-custom-image
                    [imgId]="item"
                    alt=""
                    imageClass="!w-25 !h-25 !object-cover !rounded"></app-custom-image>
                  <div
                    (click)="onRemoveImageGet(item)"
                    class="p-0.5 rounded-full bg-black/25 flex justify-center items-center w-4 h-4 absolute top-1 right-1 cursor-pointer hover:bg-black/10">
                    <i class="icon-wl-close text-white text-2"></i>
                  </div>
                </div>
              }
            }
            @for (file of fileList; track $index) {
              <div class="relative">
                @if (file?.file) {
                  <img [src]="file.originFile" alt="img" class="w-25 h-25 object-cover rounded" />
                }
                <div
                  class="p-0.5 rounded-full bg-black/25 flex justify-center items-center w-4 h-4 absolute top-1 right-1 cursor-pointer hover:bg-black/10"
                  (click)="onRemoveImage(file.id)">
                  <i class="icon-wl-close text-white text-2"></i>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      @if (this.stepLoadingNewFeed.loadingUpload || this.stepLoadingNewFeed.loadingSuccess) {
        <div class="w-full h-full absolute bg-neutral-800/20 rounded-md flex flex-col justify-center items-center">
          @if (this.stepLoadingNewFeed.loadingUpload) {
            <div class="p-6 w-[400px] justify-center items-center flex flex-col rounded-sm bg-neutral-200 gap-2">
              <p-progressBar mode="indeterminate" class="w-full" [style]="{ height: '6px ' }" />
              <span>{{ translatePrefix + 'uploading-image' | translate }}</span>
            </div>
          } @else if (this.stepLoadingNewFeed.loadingSuccess) {
            <div class="p-6 w-[400px] justify-center items-center flex flex-col rounded-sm bg-neutral-200 gap-2">
              <p-progressBar mode="indeterminate" class="w-full" [style]="{ height: '6px ' }" />
              <span>{{
                translatePrefix + (postDetail && postDetail?.id ? 'updating-post' : 'creating-new-post') | translate
              }}</span>
            </div>
          }
        </div>
      }
    </div>
  </div>

  <div class="pt-4" footer>
    <app-button
      [label]="'action.cancel' | translate"
      variant="outline-secondary"
      size="xl"
      (onClick)="onCancel()"></app-button>
    <app-button
      [label]="(postDetail?.id ? 'action.update' : 'action.create') | translate"
      size="xl"
      [disabled]="!postCreate.title || !postCreate?.description || postCreate?.description?.length === 7"
      (onClick)="onSavePost()"></app-button>
  </div>
</app-custom-dialog>
