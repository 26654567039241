import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { TreeNode } from 'primeng/api';

import { LeadDashboardComponent } from './pages/lead-dashboard/lead-dashboard.component';
import { PolicyDashboardComponent } from './pages/policy-dashboard/policy-dashboard.component';
import { PerformanceDashboardComponent } from './pages/performance-dashboard/performance-dashboard.component';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { DashboardTabOptions } from '@core/constants/dashboard';
import { DASHBOARD_TAB } from '@core/enum/dashboard';
import { UserService } from '@core/services/user.service';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import { UserSelectDropdownComponent } from '@shared/components/dropdown/user-select-dropdown/user-select-dropdown.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { UserDetail } from '@core/models/interfaces/user';
import { UserGroupService } from '@core/services/user-groups.service';
import { UserGroups } from '@core/models/interfaces/user-group';
import { TreeSelectModule } from 'primeng/treeselect';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TreeSelectModule,
    FormsModule,
    LeadDashboardComponent,
    PolicyDashboardComponent,
    PerformanceDashboardComponent,
    UserDashboardComponent,
    UserSelectDropdownComponent,
    CustomCalendarComponent,
    ButtonComponent,
    NgxPermissionsModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject();
  readonly translatePrefix = 'dashboard.';
  readonly DASHBOARD_TAB = DASHBOARD_TAB;
  readonly tabs = DashboardTabOptions;

  tabActive: DASHBOARD_TAB = DASHBOARD_TAB.LEAD;
  users: UserDetail[] = [];
  userGroups: TreeNode[] = [];
  totalUsers: number = 0;

  userSelected?: UserDetail;
  userGroupSelected: TreeNode = {
    label: ''
  };
  datesSelected: Date[] = [];
  tabActiveSet: boolean = false;
  hasPermissions: boolean = false;

  constructor(
    private userService: UserService,
    private userGroupsService: UserGroupService,
    private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit(): void {
    forkJoin([this.userService.getUsers(), this.userGroupsService.getGroups()]).subscribe({
      next: results => {
        const [userRes, userGroupsRes] = results;

        this.users = userRes.data.content;
        this.totalUsers = userRes.data.total;
        this.userGroups = userGroupsRes.data.content.map(item => this.convertUserGroupToTreeNode(item));
      }
    });
    this.userService
      .getUsers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.users = res.data.content;
          this.totalUsers = res.data.total;
        }
      });

    this.setDefaultTabBasedOnPermissions();
  }

  convertUserGroupToTreeNode(item: UserGroups): TreeNode {
    let treeNode: TreeNode = {
      key: item.id,
      label: item.name,
      data: {
        id: item.id
      },
      children: item.subGroups && item.subGroups.length > 0 ? item.subGroups.map(this.convertUserGroupToTreeNode) : [],
      leaf: !item.subGroups || item.subGroups.length === 0
    };

    return treeNode;
  }

  setDefaultTabBasedOnPermissions(): void {
    let permissionsChecked = 0;
    this.tabs.forEach(tab => {
      this.permissionsService.hasPermission(tab.permission).then(hasPermission => {
        permissionsChecked++;
        if (hasPermission) {
          this.hasPermissions = true;
          if (!this.tabActiveSet) {
            this.tabActive = tab.value;
            this.tabActiveSet = true;
          }
        }
        if (permissionsChecked === this.tabs.length && !this.hasPermissions) {
          this.hasPermissions = false;
        }
      });
    });
  }

  onClickTab(value: DASHBOARD_TAB) {
    this.tabActive = value;
  }

  get isValidReset() {
    return Boolean(
      this.userSelected?.id ||
        this.datesSelected.length === 2 ||
        (this.userGroupSelected?.key && this.tabActive === DASHBOARD_TAB.POLICY)
    );
  }

  onResetFilter() {
    this.userSelected = undefined;
    this.datesSelected = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
